import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../shared/Navbar";
import useWindowSize from "../../hooks/useWindow";
import * as urlHelp from "../../features/urlHelpers";
import {
  DataGridPremium,
  csCZ,
  GridColumns,
  useGridApiRef,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import axios from "axios";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
//import { RootState } from "../../redux/store";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
} from "@mui/material";
//import { logoutUser } from "../../redux/actions/authActions";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { getLicense } from "../shared/license";
import { getRowStatus_isCorrect } from "../shared/search";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ErrorIcon from "@mui/icons-material/Error";
import { logout, log_or_not } from "../shared/authFunctions";
import { loginData } from "../auth/redux/loginSlice";
import { errorHandleDialog } from "../shared/errorDialog";
import styles from "./DefiniceKartonuTable.module.sass";
import remove_accents from "remove-accents";

function definiceKartonu() {
  //const [definiciaData, setDediniciaData] = useState([] as GridRowsProp); //data do tabulky ziskane z backendu
  const [rows, setRows] = useState([] as GridRowsProp);
  const [rows_loaded, setRowsLoaded] = useState(false as boolean);
  const [cennik, setCennik] = useState([] as GridRowsProp);
  const dispatch = useDispatch();
  const [searchValue, setSearchedValue] = useState("" as string);
  const { width, height } = useWindowSize();
  const edit = () => toast.success("Položka byla upravena!");
  const add = () => toast.success("Položka byla přidana!");
  const error = (stlpec: string) =>
    toast.error(
      "Chyba při úpravě položky hodnoty nesmí být záporné ani desetinné číslo v " +
        stlpec +
        "!"
    );
  const apiRef = useGridApiRef();
  const navigate = useHistory();
  const logindata = useSelector(loginData);
  const success_delete = () => toast.success("Položka byla odstranena!");
  const [edit_dialog_header, setEdit_dialog_header] = useState("");

  const [open, setOpen] = React.useState(false);
  const [error_dialog, setError_dialog] = React.useState("");
  // connection ID pre notifikacie
  LicenseInfo.setLicenseKey(getLicense());

  const [open_edit, setOpen_edit] = React.useState(false);
  const [open_delete, setOpen_delete] = React.useState(false);

  const [kartonyData, setKartonyData] = useState({
    id: 0,
    poradie: 0,
    znackaKrt_cz: "",
    znackaKrt_hu: "",
    znackaKrt_at: "",
    znackaKrt_pl: "",
    znackaVyr_cz: "",
    znackaVyr_hu: "",
    znackaVyr_at: "",
    znackaVyr_pl: "",
    odberatel_at: "",
    odberatel_cz: "",
    odberatel_hu: "",
    odberatel_pl: "",
  });

  const [validationData, setValidationData] = useState({
    znackaKrt_cz_exist: true,
    znackaKrt_cz_nazov: "",
    znackaKrt_hu_exist: true,
    znackaKrt_hu_nazov: "",
    znackaKrt_at_exist: true,
    znackaKrt_at_nazov: "",
    znackaKrt_pl_exist: true,
    znackaKrt_pl_nazov: "",
    znackaVyr_cz_exist: true,
    znackaVyr_cz_nazov: "",
    znackaVyr_hu_exist: true,
    znackaVyr_hu_nazov: "",
    znackaVyr_at_exist: true,
    znackaVyr_at_nazov: "",
    znackaVyr_pl_exist: true,
    znackaVyr_pl_nazov: "",
  });
  const [znacka_data_validacia, setZnacka_data_validacia] = useState([] as any);

  useEffect(() => {
    setRowsLoaded(false);
    getDefiniciaData();
  }, []);

  const styleTable = {
    height: window.innerHeight - 210.8,
    width: "100%",
    margin: 0,
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
    },
    "& .super-app-theme--header": {
      backgroundColor: "rgba(0,102,0,255)",
      color: "rgb(255, 255, 255)",
    },
    "& .MuiDataGrid-colCell:scroll": {
      outline: "none",
    },
  };

  async function getDefiniciaData() {
    log_or_not(logindata, navigate, dispatch);
    await axios
      .get(
        urlHelp.urlKartonysdef +
          "?datum_vyroba=" +
          urlHelp.get_datum_vyroba(logindata),
        urlHelp.get_header(logindata)
      )
      .then(function (response) {
        setRows(response.data.data);
        setRowsLoaded(true);
        setZnacka_data_validacia(response.data);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function EditToolbar(props: any) {
    let i = 0;
    let new_poradie = 0;
    while (rows.length > i) {
      if (rows[i].poradie > new_poradie) {
        new_poradie = rows[i].poradie as number;
      }
      i++;
    }
    const handleClick = () => {
      setEdit_dialog_header("Přidání");
      const row = {
        id: 0,
        odberatel_at: "",
        odberatel_cz: "",
        odberatel_hu: "",
        odberatel_pl: "",
        poradie: new_poradie + 1,
        znackaKrt_at: "",
        znackaKrt_cz: "",
        znackaKrt_hu: "",
        znackaKrt_pl: "",
        znackaVyr_at: "",
        znackaVyr_cz: "",
        znackaVyr_hu: "",
        znackaVyr_pl: "",
      };
      handleEditClick(row);
    };

    return (
      <GridToolbarContainer>
        <div className={styles.GridToolbar}>
          <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Přidat
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }
  function CustomToolbarEmpty() {
    return <div></div>;
  }

  function handleEditClick(row: any) {
    console.log(row);
    if (row !== null) {
      znacka_validacia(
        row.znackaKrt_cz,
        znacka_data_validacia.znackaKrt_cz,
        "znackaKrt_cz"
      );
      znacka_validacia(
        row.znackaKrt_at,
        znacka_data_validacia.znackaKrt_at,
        "znackaKrt_at"
      );
      znacka_validacia(
        row.znackaKrt_hu,
        znacka_data_validacia.znackaKrt_hu,
        "znackaKrt_hu"
      );
      znacka_validacia(
        row.znackaKrt_pl,
        znacka_data_validacia.znackaKrt_pl,
        "znackaKrt_pl"
      );
      znacka_validacia(
        row.znackaVyr_at,
        znacka_data_validacia.znackaVyr_at,
        "znackaVyr_at"
      );
      znacka_validacia(
        row.znackaVyr_cz,
        znacka_data_validacia.znackaVyr_cz,
        "znackaVyr_cz"
      );
      znacka_validacia(
        row.znackaVyr_hu,
        znacka_data_validacia.znackaVyr_hu,
        "znackaVyr_hu"
      );
      znacka_validacia(
        row.znackaVyr_pl,
        znacka_data_validacia.znackaVyr_pl,
        "znackaVyr_pl"
      );
      //znackaVyr_cz_validation(row.znackaVyr_cz)
      setKartonyData({
        id: row.id,
        poradie: row.poradie,
        znackaKrt_cz: row.znackaKrt_cz,
        znackaKrt_hu: row.znackaKrt_hu,
        znackaKrt_at: row.znackaKrt_at,
        znackaKrt_pl: row.znackaKrt_pl,
        znackaVyr_cz: row.znackaVyr_cz,
        znackaVyr_hu: row.znackaVyr_hu,
        znackaVyr_at: row.znackaVyr_at,
        znackaVyr_pl: row.znackaVyr_pl,
        odberatel_at: row.odberatel_at,
        odberatel_cz: row.odberatel_cz,
        odberatel_hu: row.odberatel_hu,
        odberatel_pl: row.odberatel_pl,
      });
    }
    handleOpen_edit();
  }

  function handleOpen_edit() {
    setOpen_edit(true);
  }

  function handleClose_edit() {
    setOpen_edit(false);
  }

  function znacka_validacia(znacka: string, data: any, field: string) {
    if (znacka === "" || znacka === null) {
      setValidationData((prev) => ({
        ...prev,
        [field + "_exist"]: true,
        [field + "_nazov"]: "",
      }));
      return;
    }

    const row = data.filter((row: any) => row.znacka === znacka);

    if (row.length > 0) {
      setValidationData((prev) => ({
        ...prev,
        [field + "_exist"]: true,
        [field + "_nazov"]: row[0].nazov,
      }));
    } else {
      setValidationData((prev) => ({
        ...prev,
        [field + "_exist"]: false,
        [field + "_nazov"]: "Značka nenalezena",
      }));
    }
  }

  const handleChange = (e: any) => {
    const znacka = e.target.value;
    if (e.target.name === "poradie") {
      setKartonyData((prev) => ({
        ...prev,
        [e.target.name]: Number(e.target.value),
      }));
      return;
    }
    setKartonyData((prev) => ({ ...prev, [e.target.name]: znacka }));
    if (
      e.target.name !== "odberatel_cz" &&
      e.target.name !== "odberatel_hu" &&
      e.target.name !== "odberatel_at" &&
      e.target.name !== "odberatel_pl" &&
      e.target.name !== "poradie"
    ) {
      znacka_validacia(
        znacka,
        znacka_data_validacia[e.target.name],
        e.target.name
      );
    }
  };

  async function delete_item() {
    const headers = {
      Authorization: "Bearer " + logindata.userInfo.Token,
    };
    const data = {
      id: kartonyData.id,
    };
    axios
      .delete(urlHelp.urlKartonysdef, { headers, data })
      .then(function (response) {
        success_delete();
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
    setOpen_delete(false);
    getDefiniciaData();
  }

  function handleDeleteClick(row: any) {
    if (row !== null) {
      setKartonyData({
        id: row.id,
        poradie: row.poradie,
        znackaKrt_cz: row.znackaKrt_cz,
        znackaKrt_hu: row.znackaKrt_hu,
        znackaKrt_at: row.znackaKrt_at,
        znackaKrt_pl: row.znackaKrt_pl,
        znackaVyr_cz: row.znackaVyr_cz,
        znackaVyr_hu: row.znackaVyr_hu,
        znackaVyr_at: row.znackaVyr_at,
        znackaVyr_pl: row.znackaVyr_pl,
        odberatel_at: row.odberatel_at,
        odberatel_cz: row.odberatel_cz,
        odberatel_hu: row.odberatel_hu,
        odberatel_pl: row.odberatel_pl,
      });
    }

    setOpen_delete(true);
  }

  function handleClose_delete() {
    setOpen_delete(false);
  }

  async function handleSaveClick() {
    console.log(kartonyData);
    await axios
      .post(
        urlHelp.urlKartonysdefUpdate,
        {
          id: kartonyData.id,
          poradie: kartonyData.poradie as number,
          znackaKrt_cz: kartonyData.znackaKrt_cz,
          znackaKrt_hu: kartonyData.znackaKrt_hu,
          znackaKrt_at: kartonyData.znackaKrt_at,
          znackaKrt_pl: kartonyData.znackaKrt_pl,
          znackaVyr_cz: kartonyData.znackaVyr_cz,
          znackaVyr_hu: kartonyData.znackaVyr_hu,
          znackaVyr_at: kartonyData.znackaVyr_at,
          znackaVyr_pl: kartonyData.znackaVyr_pl,
          odberatel_at: kartonyData.odberatel_at,
          odberatel_cz: kartonyData.odberatel_cz,
          odberatel_hu: kartonyData.odberatel_hu,
          odberatel_pl: kartonyData.odberatel_pl,
        },
        urlHelp.get_header(logindata)
      )
      .then(function (response) {
        if (kartonyData.id === 0) {
          add();
        } else {
          edit();
        }
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
    getDefiniciaData();
    setOpen_edit(false);
  }

  const columns: GridColumns = [
    {
      field: "poradie",
      headerName: "Pořadí",
      type: "number",
      width: 80,
      headerAlign: "center",
    },
    {
      field: "znackaKrt_cz",
      headerName: "Kartón CZ",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "znackaVyr_cz",
      headerName: "Výrobek CZ",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "znackaKrt_hu",
      headerName: "Kartón HU",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "znackaVyr_hu",
      headerName: "Výrobek HU",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "znackaKrt_at",
      headerName: "Kartón AT",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "znackaVyr_at",
      headerName: "Výrobek AT",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "znackaKrt_pl",
      headerName: "Kartón PL",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "znackaVyr_pl",
      headerName: "Výrobek PL",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "odberatel_cz",
      headerName: "Odběratel CZ",
      type: "number",
      width: 120,
      headerAlign: "center",
    },
    {
      field: "odberatel_hu",
      headerName: "Odběratel HU",
      type: "number",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "odberatel_at",
      headerName: "Odběratel AT",
      type: "number",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "odberatel_pl",
      headerName: "Odběratel PL",
      type: "number",
      width: 100,
      headerAlign: "center",
    },
    {
      field: "Edituj",
      headerAlign: "center",
      width: 80,
      pinnable: true,
      renderCell: (params: any) => (
        <>
          <Button
            onClick={() => {
              handleEditClick(params.row);
              setEdit_dialog_header("Úprava");
            }}
          >
            <ModeEditOutlineIcon />
          </Button>
        </>
      ),
    },
    {
      field: "Smaž",
      headerAlign: "center",
      width: 80,
      pinnable: true,
      renderCell: (params: any) => (
        <>
          <Button
            color="error"
            onClick={() => {
              handleDeleteClick(params.row);
            }}
          >
            <DeleteIcon />
          </Button>
        </>
      ),
    },
  ];
  //  Definice kartonů
  return (
    <div>
      <Navbar />

      <div
        style={{
          height: window.innerHeight - 70.8,
        }}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <input
              onChange={(e) => {
                setSearchedValue(e.target.value);
              }}
              className={styles.input}
              placeholder="Hledej"
            ></input>
            <div className={styles.title}>Definice kartonů</div>
          </div>

          <Dialog
            open={open_edit}
            onClose={handleClose_edit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {edit_dialog_header}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <MuiGrid container spacing={0.15}>
                  <MuiGrid item xs={2.5}>
                    <Box
                      sx={{
                        color: "text.secondary",
                        mt: 3,
                        fontWeight: "bold",
                      }}
                    >
                      Pořadí
                    </Box>
                  </MuiGrid>
                  <MuiGrid item xs={3}>
                    <TextField
                      margin="normal"
                      value={kartonyData.poradie}
                      label="pořadí"
                      size="small"
                      type="number"
                      name="poradie"
                      onChange={handleChange}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6.5}>
                    <Box sx={{ mt: 3, ml: 0.5 }}>
                      <div></div>
                    </Box>
                  </MuiGrid>
                  <MuiGrid item xs={2.5}>
                    <Box
                      sx={{
                        color: "text.secondary",
                        mt: 3,
                        fontWeight: "bold",
                      }}
                    >
                      Kartón CZ
                    </Box>
                  </MuiGrid>
                  <MuiGrid item xs={3}>
                    <TextField
                      margin="normal"
                      value={kartonyData.znackaKrt_cz}
                      label="značka"
                      size="small"
                      name="znackaKrt_cz"
                      onChange={handleChange}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6.5}>
                    <Box
                      sx={{
                        color: validationData.znackaKrt_cz_exist
                          ? "text.secondary"
                          : "red",
                        mt: 3,
                        ml: 0.5,
                      }}
                    >
                      {" "}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div hidden={validationData.znackaKrt_cz_exist}>
                          <ErrorIcon />
                        </div>
                        <div>{validationData.znackaKrt_cz_nazov}</div>
                      </div>
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={2.5}>
                    <Box sx={{ color: "text.secondary", mt: 3 }}>
                      Výrobek CZ
                    </Box>
                  </MuiGrid>
                  <MuiGrid item xs={3}>
                    <TextField
                      margin="normal"
                      value={kartonyData.znackaVyr_cz}
                      label="značka"
                      size="small"
                      name="znackaVyr_cz"
                      onChange={handleChange}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6.5}>
                    <Box
                      sx={{
                        color: validationData.znackaVyr_cz_exist
                          ? "text.secondary"
                          : "red",
                        mt: 3,
                        ml: 0.5,
                      }}
                    >
                      {" "}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div hidden={validationData.znackaVyr_cz_exist}>
                          <ErrorIcon />
                        </div>
                        <div>{validationData.znackaVyr_cz_nazov}</div>
                      </div>
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={2.5}>
                    <Box sx={{ color: "text.secondary", mt: 3 }}>
                      Odběratel CZ
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={9}>
                    <TextField
                      margin="normal"
                      value={kartonyData.odberatel_cz}
                      label="Odběratel CZ"
                      size="small"
                      fullWidth
                      name="odberatel_cz"
                      onChange={handleChange}
                    />
                  </MuiGrid>

                  <MuiGrid item xs={2.5}>
                    <Box
                      sx={{
                        color: "text.secondary",
                        mt: 3,
                        fontWeight: "bold",
                      }}
                    >
                      Kartón HU
                    </Box>
                  </MuiGrid>
                  <MuiGrid item xs={3}>
                    <TextField
                      margin="normal"
                      value={kartonyData.znackaKrt_hu}
                      label="značka"
                      size="small"
                      name="znackaKrt_hu"
                      onChange={handleChange}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6.5}>
                    <Box
                      sx={{
                        color: validationData.znackaKrt_hu_exist
                          ? "text.secondary"
                          : "red",
                        mt: 3,
                        ml: 0.5,
                      }}
                    >
                      {" "}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div hidden={validationData.znackaKrt_hu_exist}>
                          <ErrorIcon />
                        </div>
                        <div>{validationData.znackaKrt_hu_nazov}</div>
                      </div>
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={2.5}>
                    <Box sx={{ color: "text.secondary", mt: 3 }}>
                      Výrobek HU
                    </Box>
                  </MuiGrid>
                  <MuiGrid item xs={3}>
                    <TextField
                      margin="normal"
                      value={kartonyData.znackaVyr_hu}
                      label="značka"
                      size="small"
                      name="znackaVyr_hu"
                      onChange={handleChange}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6.5}>
                    <Box
                      sx={{
                        color: validationData.znackaVyr_hu_exist
                          ? "text.secondary"
                          : "red",
                        mt: 3,
                        ml: 0.5,
                      }}
                    >
                      {" "}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div hidden={validationData.znackaVyr_hu_exist}>
                          <ErrorIcon />
                        </div>
                        <div>{validationData.znackaVyr_hu_nazov}</div>
                      </div>
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={2.5}>
                    <Box sx={{ color: "text.secondary", mt: 3 }}>
                      Odběratel HU
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={9}>
                    <TextField
                      margin="normal"
                      value={kartonyData.odberatel_hu}
                      label="Odběratel HU"
                      size="small"
                      fullWidth
                      name="odberatel_hu"
                      onChange={handleChange}
                    />
                  </MuiGrid>

                  <MuiGrid item xs={2.5}>
                    <Box
                      sx={{
                        color: "text.secondary",
                        mt: 3,
                        fontWeight: "bold",
                      }}
                    >
                      Kartón AT
                    </Box>
                  </MuiGrid>
                  <MuiGrid item xs={3}>
                    <TextField
                      margin="normal"
                      value={kartonyData.znackaKrt_at}
                      label="značka"
                      size="small"
                      name="znackaKrt_at"
                      onChange={handleChange}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6.5}>
                    <Box
                      sx={{
                        color: validationData.znackaKrt_at_exist
                          ? "text.secondary"
                          : "red",
                        mt: 3,
                        ml: 0.5,
                      }}
                    >
                      {" "}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div hidden={validationData.znackaKrt_at_exist}>
                          <ErrorIcon />
                        </div>
                        <div>{validationData.znackaKrt_at_nazov}</div>
                      </div>
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={2.5}>
                    <Box sx={{ color: "text.secondary", mt: 3 }}>
                      Výrobek AT
                    </Box>
                  </MuiGrid>
                  <MuiGrid item xs={3}>
                    <TextField
                      margin="normal"
                      value={kartonyData.znackaVyr_at}
                      label="značka"
                      size="small"
                      name="znackaVyr_at"
                      onChange={handleChange}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6.5}>
                    <Box
                      sx={{
                        color: validationData.znackaVyr_at_exist
                          ? "text.secondary"
                          : "red",
                        mt: 3,
                        ml: 0.5,
                      }}
                    >
                      {" "}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div hidden={validationData.znackaVyr_at_exist}>
                          <ErrorIcon />
                        </div>
                        <div>{validationData.znackaVyr_at_nazov}</div>
                      </div>
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={2.5}>
                    <Box sx={{ color: "text.secondary", mt: 3 }}>
                      Odběratel AT
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={9}>
                    <TextField
                      margin="normal"
                      value={kartonyData.odberatel_at}
                      label="Odběratel AT"
                      size="small"
                      fullWidth
                      name="odberatel_at"
                      onChange={handleChange}
                    />
                  </MuiGrid>

                  <MuiGrid item xs={2.5}>
                    <Box
                      sx={{
                        color: "text.secondary",
                        mt: 3,
                        fontWeight: "bold",
                      }}
                    >
                      Kartón PL
                    </Box>
                  </MuiGrid>
                  <MuiGrid item xs={3}>
                    <TextField
                      margin="normal"
                      value={kartonyData.znackaKrt_pl}
                      label="značka"
                      size="small"
                      name="znackaKrt_pl"
                      onChange={handleChange}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6.5}>
                    <Box
                      sx={{
                        color: validationData.znackaKrt_pl_exist
                          ? "text.secondary"
                          : "red",
                        mt: 3,
                        ml: 0.5,
                      }}
                    >
                      {" "}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div hidden={validationData.znackaKrt_pl_exist}>
                          <ErrorIcon />
                        </div>
                        <div>{validationData.znackaKrt_pl_nazov}</div>
                      </div>
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={2.5}>
                    <Box sx={{ color: "text.secondary", mt: 3 }}>
                      Výrobek PL
                    </Box>
                  </MuiGrid>
                  <MuiGrid item xs={3}>
                    <TextField
                      margin="normal"
                      value={kartonyData.znackaVyr_pl}
                      label="značka"
                      size="small"
                      name="znackaVyr_pl"
                      onChange={handleChange}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6.5}>
                    <Box
                      sx={{
                        color: validationData.znackaVyr_pl_exist
                          ? "text.secondary"
                          : "red",
                        mt: 3,
                        ml: 0.5,
                      }}
                    >
                      {" "}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div hidden={validationData.znackaVyr_pl_exist}>
                          <ErrorIcon />
                        </div>
                        <div>{validationData.znackaVyr_pl_nazov}</div>
                      </div>
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={2.5}>
                    <Box sx={{ color: "text.secondary", mt: 3 }}>
                      Odběratel PL
                    </Box>
                  </MuiGrid>

                  <MuiGrid item xs={9}>
                    <TextField
                      margin="normal"
                      value={kartonyData.odberatel_pl}
                      label="Odběratel PL"
                      size="small"
                      fullWidth
                      name="odberatel_pl"
                      onChange={handleChange}
                    />
                  </MuiGrid>
                </MuiGrid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose_edit();
                }}
              >
                <CancelIcon /> Zrušiť
              </Button>

              <Button
                disabled={
                  !validationData.znackaKrt_at_exist ||
                  !validationData.znackaKrt_cz_exist ||
                  !validationData.znackaKrt_hu_exist ||
                  !validationData.znackaKrt_pl_exist ||
                  !validationData.znackaVyr_at_exist ||
                  !validationData.znackaVyr_cz_exist ||
                  !validationData.znackaVyr_hu_exist ||
                  !validationData.znackaVyr_pl_exist
                }
                onClick={() => {
                  handleSaveClick();
                }}
              >
                <SaveIcon /> Uložiť
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open_delete}
            onClose={handleClose_delete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Chcete smazat ?"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={delete_item}>Ano</Button>
              <Button onClick={handleClose_delete}>Ne</Button>
            </DialogActions>
          </Dialog>
          <div className={styles.grid}>
            {rows_loaded ? (
              <DataGridPremium
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                apiRef={apiRef}
                components={{
                  Toolbar: rows.length === 0 ? CustomToolbarEmpty : EditToolbar,
                }}
                sx={styleTable}
                hideFooter
                disableColumnReorder
                //znackaKrt_cz
                rows={rows.filter((row) =>
                  String(
                    remove_accents(
                      row.znackaKrt_cz ? row.znackaKrt_cz.toUpperCase() : ""
                    )
                  ).includes(remove_accents(searchValue.toUpperCase()))
                )}
                columns={columns}
                rowHeight={40}
                initialState={{ pinnedColumns: { right: ["Edituj", "Smaž"] } }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2} />
    </div>
  );
}

export default definiceKartonu;
