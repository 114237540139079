import React from "react";
import "./Error404.css";

function Error404() {
    return (
        <div>
            <h1 className="flex justify-center align-top mt-4">404 Stránka nebyla nalezena</h1>
            <div className="mt-4">
            <a className="flex justify-center align-top errorLink" href="/">Návrat na hlavní stránku</a>
            </div>
        </div>    
    )
}

export default Error404;