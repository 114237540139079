import React, { useState } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
} from "mdb-react-ui-kit";
import Button, { ButtonProps } from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
//import { RootState } from "../../redux/store";
import { useHistory } from "react-router-dom";
import { Divider, Menu, MenuItem } from "@mui/material";
import Fade from "@mui/material/Fade";
import * as urlHelp from "../../features/urlHelpers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
} from "@mui/material";
import axios from "axios";
import { logout, log_or_not } from "../shared/authFunctions";
import { loginData } from "../auth/redux/loginSlice";
import swal from "sweetalert";
import { errorHandleDialog } from "./errorDialog";

export default function App(props: any) {
  const [showNav, setShowNav] = useState(false);
  const [anchorEl_admin, setAnchorEl_admin] =
    React.useState<null | HTMLElement>(null);
  const open_admin = Boolean(anchorEl_admin);
  const [anchorEl_user, setAnchorEl_user] = React.useState<null | HTMLElement>(
    null
  );
  const open_user = Boolean(anchorEl_user);
  const dispatch = useDispatch();
  const navigate = useHistory();
  const logindata = useSelector(loginData);
  const [open, setOpen] = React.useState(false);
  const [open_hlaska, setOpen_hlaska] = React.useState(false);
  const [hlaska, setHlaska] = React.useState("");

  const handleClick_admin = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl_admin(event.currentTarget);
  };
  const handleClose_admin = () => {
    setAnchorEl_admin(null);
  };

  const handleClick_user = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl_user(event.currentTarget);
  };
  const handleClose_user = () => {
    setAnchorEl_user(null);
  };

  function handleClose() {
    setOpen(false);
  }

  async function aktualizacia() {
    await axios
      .get(
        urlHelp.aktualizaciaVyrRozpisUrl +
          "?datum_vyroba=" +
          urlHelp.get_datum_vyroba(logindata),
        urlHelp.get_header(logindata)
      )
      .then(function (response) {
        swal("Povedlo se!", response.data, "success");
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
    setOpen(false);
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Zpracovat kartony? Původní data budou předpsána."}
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={() => {
              aktualizacia();
            }}
          >
            Ano
          </Button>
          <Button onClick={handleClose}>Ne</Button>
        </DialogActions>
      </Dialog>

      <MDBNavbar
        expand="lg"
        light
        style={{
          minWidth: innerWidth,
          backgroundColor: "#23A638",
          color: "white",
        }}
      >
        <MDBContainer fluid>
          <MDBNavbarToggler
            type="button"
            aria-expanded="true"
            aria-label="Toggle navigation"
            onClick={() => setShowNav(!showNav)}
          >
            <MenuIcon style={{ color: "white" }} />
          </MDBNavbarToggler>
          <MDBNavbarBrand style={{ fontSize: 28 }} href="/menu">
            Crocodille
          </MDBNavbarBrand>
          <MDBCollapse navbar show={showNav}>
            <MDBNavbarNav>
              <MDBNavbarItem>
                {props.page === "menu" ? (
                  <div></div>
                ) : (
                  <MDBNavbarLink
                    style={{ color: "white" }}
                    href="/vyrobaRozpis"
                  >
                    Vyroba rozpis
                  </MDBNavbarLink>
                )}
              </MDBNavbarItem>
              <MDBNavbarItem>
                {props.page === "menu" ? (
                  <div></div>
                ) : (
                  <MDBNavbarLink
                    style={{ color: "white" }}
                    href="/kartonyRozpis"
                  >
                    Kartonovy Rozpis
                  </MDBNavbarLink>
                )}
              </MDBNavbarItem>
              <MDBNavbarItem>
                {props.page === "menu" ? (
                  <div></div>
                ) : (
                  <MDBNavbarLink style={{ color: "white" }} href="/skladPraha">
                    Sklad Praha
                  </MDBNavbarLink>
                )}
              </MDBNavbarItem>
              <MDBNavbarItem>
                {props.page === "menu" ? (
                  <div></div>
                ) : (
                  <MDBNavbarLink style={{ color: "white" }} href="/zvy">
                    ZVY
                  </MDBNavbarLink>
                )}
              </MDBNavbarItem>
              <MDBNavbarItem>
                {props.page === "menu" ? (
                  <div></div>
                ) : (
                  <MDBNavbarLink style={{ color: "white" }} href="/zpe">
                    ZPE
                  </MDBNavbarLink>
                )}
              </MDBNavbarItem>
              <MDBNavbarItem>
                {props.page === "menu" ? (
                  <div></div>
                ) : (
                  <MDBNavbarLink style={{ color: "white" }} href="/ztp">
                    ZTP
                  </MDBNavbarLink>
                )}
              </MDBNavbarItem>
              <MDBNavbarItem>
                {props.page === "menu" ? (
                  <div></div>
                ) : (
                  <MDBNavbarLink style={{ color: "white" }} href="/pecivo">
                    Pečivo
                  </MDBNavbarLink>
                )}
              </MDBNavbarItem>
            </MDBNavbarNav>

            <form className="d-flex input-group w-auto">
              <MDBNavbarLink>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {logindata.UserData.admin ? (
                    <div style={{ flex: 1 }}>
                      {" "}
                      <Button
                        sx={{
                          color: "white",
                          textTransform: "none",
                          fontWeight: "bold",
                        }}
                        id="fade-button"
                        aria-controls={open_admin ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open_admin ? "true" : undefined}
                        onClick={handleClick_admin}
                      >
                        Admin
                      </Button>
                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEl_admin}
                        open={open_admin}
                        onClose={handleClose_admin}
                        TransitionComponent={Fade}
                      >
                        <MenuItem
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          {" "}
                          <MDBNavbarLink>Zpracování kartonů</MDBNavbarLink>
                        </MenuItem>
                        <MenuItem>
                          <MDBNavbarLink href="/datumVyroby">
                            Změna datumu výroby
                          </MDBNavbarLink>
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem>
                          {" "}
                          <MDBNavbarLink href="/definice_vyrrozpis">
                            Definice vyrobního rozpisu
                          </MDBNavbarLink>
                        </MenuItem>
                        <MenuItem>
                          {" "}
                          <MDBNavbarLink href="/definiceKartonu">
                            Definice kartonů
                          </MDBNavbarLink>
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />

                        <MenuItem>
                          {" "}
                          <MDBNavbarLink href="/uzivatel">
                            Seznam uživatelů
                          </MDBNavbarLink>
                        </MenuItem>
                      </Menu>
                    </div>
                  ) : (
                    <div />
                  )}

                  <div style={{ flex: 1, marginLeft: "1%" }}>
                    {" "}
                    <Button
                      sx={{
                        color: "white",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                      id="fade-button"
                      aria-controls={open_user ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open_user ? "true" : undefined}
                      onClick={handleClick_user}
                    >
                      <AccountCircleIcon style={{ marginRight: "2%" }} />
                      {logindata.UserData.username}
                    </Button>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl_user}
                      open={open_user}
                      onClose={handleClose_user}
                      TransitionComponent={Fade}
                    >
                      <MenuItem>
                        <MDBNavbarLink onClick={handleClose_user}>
                          Profil
                        </MDBNavbarLink>
                      </MenuItem>
                      <MenuItem>
                        <MDBNavbarLink
                          onClick={() => {
                            logout(navigate, dispatch);
                          }}
                        >
                          Logout
                        </MDBNavbarLink>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </MDBNavbarLink>
            </form>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
}
