import React from "react";
import "./Error404.css";

function Error405() {
    return (
        <div>
            <h1 className="flex justify-center align-top mt-4">Chyba nacitania dat!</h1>
            <div className="mt-4">
                <a className="flex justify-center align-top errorLink" href="/menu">Návrat na hlavní stránku</a>
                <a className="flex justify-center align-top errorLink" href="/">Logout</a>
            </div>
        </div>
    )
}

export default Error405;