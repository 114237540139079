import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import Navbar from "../shared/Navbar";
import { errorHandleDialog } from "../shared/errorDialog";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
//import { RootState } from "../../redux/store";
import DatePicker from "react-datepicker";
import * as urlHelp from "../../features/urlHelpers";
import { ToastContainer, toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { logout, log_or_not } from "../shared/authFunctions";
import styles from "./DatumVyrobaForm.module.sass";

import cs from "date-fns/locale/cs";
import { login, loginData } from "../auth/redux/loginSlice";
import axios from "axios";
import { useHistory } from "react-router-dom";

function DatumVyroba() {
  const success = () => toast.success("Datum byl změněn");
  const dispatch = useDispatch();
  const navigate = useHistory();
  const logindata = useSelector(loginData);
  const [oldDate, setOldDate] = useState(
    new Date(
      logindata.ConfigData === undefined
        ? ""
        : logindata.ConfigData.datum_vyroba
    )
  );
  const [newDate, setNewDate] = useState(
    new Date(
      logindata.ConfigData === undefined
        ? ""
        : logindata.ConfigData.datum_vyroba
    )
  );

  useEffect(() => {
    log_or_not(logindata, navigate, dispatch);
    console.log(new Date(logindata.ConfigData.datum_vyroba));
    console.log(new Date());
  }, []);

  return (
    <div>
      <Navbar />

      <div
        style={{ height: window.innerHeight - 70.8 }}
        className={styles.container}
      >
        <div className={styles.content}>
          <div className={styles.DatePicker}>
            <div>Původní datum</div>
            <DatePicker
              dateFormat="dd.MM.y"
              locale={cs}
              selected={oldDate}
              disabled={true}
              onChange={(date: Date) => setOldDate(date)}
              customInput={
                <input
                  className={styles.input}
                  type="text"
                  id="validationCustom01"
                  placeholder="First name"
                />
              }
            />
          </div>
          <div className={styles.DatePicker}>
            <div>Nový datum</div>

            <DatePicker
              dateFormat="dd.MM.y"
              locale={cs}
              selected={newDate}
              onChange={(date: Date) => setNewDate(date)}
              customInput={
                <input
                  className={styles.input}
                  type="text"
                  id="validationCustom01"
                  placeholder="First name"
                />
              }
            />
          </div>
          <div className={styles.DatePicker}>
            <Button
              onClick={async () => {
                //dispatch(updateTokenUser(urlHelp.get_token(logindata), newDate))
                //
                await axios
                  .get(urlHelp.userRefreshUrl, urlHelp.get_header(logindata))
                  .then(function (response: any) {
                    let data = response.data;
                    data.ConfigData.datum_vyroba = newDate;
                    dispatch(login({ data: data } as any));
                    //data.2022-03-01T00:00:00
                  })
                  .catch(function (error) {
                    console.log(error.response);
                    errorHandleDialog(error.response, navigate, dispatch);
                  });

                success();
                //dispatch(getAllZtpData(logindata.userInfo.Token, newDate.toString(), "get"))
                setOldDate(newDate);
              }}
              variant="contained"
              color="success"
            >
              Změnit
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2} />
    </div>
  );
}

export default DatumVyroba;
