import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../shared/Navbar";
import useWindowSize from "../../hooks/useWindow";
import * as urlHelp from "../../features/urlHelpers";
import { DataGridPremium, csCZ, GridToolbarProps, GridEditRowsModel, GridColumns, GridCallbackDetails, GridCellParams, GridRowId, GridRowParams, GridToolbar, MuiBaseEvent, MuiEvent, visibleGridColumnsSelector, useGridApiRef, gridVisibleSortedRowIdsSelector, gridVisibleRowCountSelector, visibleGridColumnsLengthSelector, GridToolbarFilterButton, GridToolbarDensitySelector, GridRowModelUpdate, GridRowsProp, GridEditCellValueParams, GridValueFormatterParams, GridEditCellPropsParams, GridCellEditCommitParams, GridActionsCellItem, GridEventListener, GridEvents, GridToolbarContainer, GridRenderCellParams } from '@mui/x-data-grid-premium';
import axios from "axios";
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
//import { RootState } from "../../redux/store";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input } from "@mui/material";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { getLicense } from "../shared/license"
import { getRowStatus_isCorrect } from "../shared/search"
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddIcon from '@mui/icons-material/Add';
import MuiGrid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { logout, log_or_not } from "../shared/authFunctions"
import { login, loginData } from "../auth/redux/loginSlice";
import { errorHandleDialog } from "../shared/errorDialog";

function NoRowsOverlay() {
    return (
        <Stack height="100%" alignItems="center" justifyContent="center">
        </Stack>
    );
};

function def_vyrrozpisTable() {
    const [definiciaData, setDediniciaData] = useState([] as GridRowsProp); //data do tabulky ziskane z backendu
    const [rows, setRows] = useState([] as GridRowsProp)
    const [rows_loaded, setRowsLoaded] = useState(false as boolean)
    const [cennik, setCennik] = useState([] as GridRowsProp)
    const dispatch = useDispatch();
    const [searchValue, setSearchedValue] = useState("" as string);
    const { width, height } = useWindowSize();
    const success = (stlpec: string) => toast.success("Položka byla upravena v " + stlpec + "!");
    const success_delete = () => toast.success("Použivatel byl odstranen!");
    const edit = () => toast.success("Položka byla upravena!");
    const add = () => toast.success("Položka byla přidana!");
    const error_pass = () => toast.error("Hesla sa nezhodují skuste to ješte jednou!");
    const error = (stlpec: string) => toast.error("Chyba při úpravě položky hodnoty nesmí být záporné ani desetinné číslo v " + stlpec + "!");
    const apiRef = useGridApiRef();
    const navigate = useHistory();
    const logindata = useSelector(loginData);
    const [edit_dialog_header, setEdit_dialog_header] = useState("");
    LicenseInfo.setLicenseKey(getLicense());

    //otvára delete dialog
    const [open_delete, setOpen_delete] = React.useState(false);

    //otvára error dialog
    const [open, setOpen] = React.useState(false);

    //otvára edit dialog
    const [open_edit, setOpen_edit] = React.useState(false);

    //obsah chyby pre eeror dialog
    const [error_dialog, setError_dialog] = React.useState("");


    //data do edit dialogu
    const [vyrrozpisData, setVyrrozpisData] = useState({
        id: 0,
        poradie: 0,
        nazov: "",
        znacka: "",
    })

    //ked validujem znacku a je zla disablnem button save a dam hlasku nato sluzi tento State
    const [validationData, setValidationData] = useState({
        znacka_exist: true,
        znacka_nazov: "",
    })


    useEffect(() => {

        getDefiniciaData();
    }, []);


    //style pre tabulku
    const StyledBox = styled(Box)(({ theme }) => ({
        height: innerHeight - innerHeight / 4.5,
        maxWidth: rows.length === 0 ? "100%" : 700,
        maxHeight: rows.length === 0 ? 3 : (rows.length * 40) + 60,
        width: "100%",
        '& .MuiDataGrid-cell--editable': {
            backgroundColor: 'rgb(195, 247, 195)',
            '&:hover': {
                backgroundColor: 'rgb(240,250,190)',
            }
        },
        '& .super-app-theme--header': {
            backgroundColor: 'rgba(0,102,0,255)',
            color: 'rgb(255, 255, 255)',
        },
    }));


    //natihnutie dat z api
    async function getDefiniciaData() {
        log_or_not(logindata, navigate, dispatch)
        await axios.get(urlHelp.urlVrozpisdef + "?datum_vyroba=" + urlHelp.get_datum_vyroba(logindata),
            urlHelp.get_header(logindata))
            .then(function (response) {
                console.log(response)
                const newData = response.data.data
                console.log(newData)
                setDediniciaData(newData);
                setCennik(response.data.cennik)
                search(newData, searchValue);
            })
            .catch(function (error) {
                errorHandleDialog(error.response, navigate, dispatch);
            })
    }

    //handler pre searchovanie v quick search
    function Search(e: any) {
        e.target.focus
        const value = e.target.value.toString().toLowerCase() as string
        setSearchedValue(value)
        search(definiciaData, value)

    }

    //funkcia ktora upravuje data riadkov po hladani ktore sa maju zobrazit
    function search(data: GridRowsProp, search_value: string) {
        let i = 0;
        let result = []
        while (i < data.length) {
            const columns = [data[i].nazov, data[i].znacka];
            if (getRowStatus_isCorrect(search_value, columns)) {
                result.push(data[i])
            }
            i++;
        }
        setRows(result)
        setRowsLoaded(true)
    }

    //delete dialog///////////////////////////

    function handleDeleteClick(row: any) {
        setVyrrozpisData({
            id: row.id,
            poradie: row.poradie,
            znacka: row.znacka,
            nazov: row.nazov,
        })

        setOpen_delete(true)

    };


    function handleClose_delete() {
        setOpen_delete(false);

    };


    async function delete_item() {
        console.log(vyrrozpisData)
        const headers = {
            'Authorization': 'Bearer ' + logindata.Token
        }
        const data = {
            id: vyrrozpisData.id,
        }
        await axios.delete(urlHelp.urlVrozpisdef, { headers, data })
            .then(function (response) {
                success_delete();
               
            })
            .catch(function (error) {
                errorHandleDialog(error.response, navigate, dispatch)

            })
        setOpen_delete(false)
        getDefiniciaData();
    };
    ///////////////////////////////////////////

    //edit dialog//////////////////////////////

    function handleEditClick(row: any) {
        console.log(row)
        if (row !== null) {

            znacka_validacia(row.znacka, cennik, "znacka")

            setVyrrozpisData({
                id: row.id,
                poradie: row.poradie,
                znacka: row.znacka,
                nazov: row.nazov,
            })

        };
        handleOpen_edit()
    };

    function handleOpen_edit() {
        setOpen_edit(true);

    };

    function handleClose_edit() {
        setOpen_edit(false);

    };

    //////////////////////////////////////

    // validacia znacky

    function znacka_validacia(znacka: string, data: any, field: string) {
        if (znacka === "" || znacka === null) {
            setValidationData(prev => ({ ...prev, [field + "_exist"]: true, [field + "_nazov"]: "" }));
            return
        }

        const row = data.filter((row: any) => row.znacka === znacka)

        if (row.length > 0) {

            setValidationData(prev => ({ ...prev, [field + "_exist"]: true, [field + "_nazov"]: row[0].nazov }));
        }
        else {
            setValidationData(prev => ({ ...prev, [field + "_exist"]: false, [field + "_nazov"]: "Značka nenalezena" }));
        }

    }

    //header +pridanie 

    function EditToolbar(props: any) {
        let i = 0;
        let new_poradie = 0;
        while (definiciaData.length > i) {
            if (definiciaData[i].poradie > new_poradie) {
                new_poradie = definiciaData[i].poradie;
            }
            i++;
        }
        const handleClick = () => {
            setEdit_dialog_header("Přidání")
            const row = {
                id: 0,
                poradie: new_poradie + 1,
                znacka: "",
                nazov: "",
            }
            handleEditClick(row)
        };

        return (
            <GridToolbarContainer>

                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    Přidat
                </Button>
            </GridToolbarContainer>
        );
    }
    function CustomToolbarEmpty() {
        return (
            <div>

            </div>
        );
    }

    // handlovanie po pisani v dialogu

    const handleChange = (e: any) => {
        e.preventDefault();
        if (e.target.name === "poradie") {
            setVyrrozpisData(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }));
            return
        }
        const znacka = e.target.value;
        
        setVyrrozpisData(prev => ({ ...prev, [e.target.name]: znacka }));
        if (e.target.name !== "poradie") {
            znacka_validacia(znacka, cennik, e.target.name)
        }
    };


    //uloženie volanie api
    async function handleSaveClick() {
        console.log(vyrrozpisData)
        await axios.post(urlHelp.urlVrozpisdefUpdate, {
            id: vyrrozpisData.id,
            poradie: vyrrozpisData.poradie as number,
            znacka: vyrrozpisData.znacka,

        },
            urlHelp.get_header(logindata))
            .then(function (response) {
                if (vyrrozpisData.id === 0) {
                    add()
                } else {
                    edit()
                }

            })
            .catch(function (error) {
                errorHandleDialog(error.response, navigate, dispatch)

            })
        getDefiniciaData();
        //console.log(vyrrozpisData.id, vyrrozpisData.poradie, vyrrozpisData.znacka)
        setOpen_edit(false);
    };


    //stlpce
    const columns: GridColumns = [
        {
            field: 'id',
            headerName: 'Id',
            type: "number",
            width: 60,
            headerAlign: "center",
        },
        {
            field: 'poradie', headerName: 'Pořadí',
            type: "number",
            width: 100,
            headerAlign: "center",



        },
        {
            field: 'znacka',
            headerName: 'Kod',
            width: 100,
            headerAlign: "center",



        },
        {
            field: 'nazov',
            headerName: 'Nazov',
            headerAlign: "center",
            width: 260,


        },
        {
            field: 'Edituj',
            headerAlign: "center",
            width: 80,
            pinnable: true,
            renderCell: (params: any) => (
                <>
                    <Button onClick={() => {
                        handleEditClick(params.row)
                        setEdit_dialog_header("Úprava")
                    }}>
                        <ModeEditOutlineIcon />
                    </Button>

                </>
            ),
        },
        {
            field: 'Smaž',
            headerAlign: "center",
            width: 80,
            pinnable: true,
            renderCell: (params: any) => (
                <>
                    <Button color="error" onClick={() => {
                        handleDeleteClick(params.row)

                    }}>
                        <DeleteIcon />
                    </Button>

                </>
            ),
        },


    ];

    return (
        <div>
            <Navbar />
            
            <div style={{ marginLeft: innerWidth >= 1025 ? "10%" : "2%", marginRight: innerWidth >= 1025 ? "10%" : "2%", minWidth: 279, marginTop: "1%" }}>
                {/*//header//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                <div style={{ display: "flex", flexDirection: "row", backgroundColor: "#494529" }}>

                    {width < 500 ? (
                        <div style={{ flex: 1, color: "white", margin: "1.5%", textAlign: "start" }}>
                            <input
                                style={{ backgroundColor: "transparent", borderColor: "white", borderWidth: 1, borderRadius: 5, width: "80%", textAlign: "center" }}
                                value={searchValue}
                                onChange={Search}
                                placeholder="Hledej"

                            />

                        </div>
                    ) : (
                        <div style={{ flex: 1, color: "white", margin: "1.5%", textAlign: "start" }}>
                            <input
                                style={{ backgroundColor: "transparent", borderColor: "white", borderWidth: 1, borderRadius: 5, textAlign: "center" }}
                                value={searchValue}
                                onChange={Search}
                                placeholder="Hledej"

                            />

                        </div>
                    )}



                    {width < 500 ? (
                        <div style={{ flex: 1, textAlign: 'end', fontSize: 15, color: "white", margin: "1%", justifyContent: "center" }}>
                            Definice vyrobního rozpisu
                        </div>
                    ) : (
                        <div style={{ flex: 1, textAlign: 'end', fontSize: 22, color: "white", margin: "1%", justifyContent: "center" }}>
                            Definice vyrobního rozpisu
                        </div>
                    )}
                </div>
                {/*//header//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

                {/*//hlaska pri nacitavani dat//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                {rows.length === 0 && rows_loaded
                    ? <div style={{ textAlign: "center", justifyContent: "center" }}>Žádne záznamy</div>
                    : <div></div>
                }
                {rows.length === 0 && !rows_loaded
                    ? <div style={{ textAlign: "center", justifyContent: "center" }}>Načítavám data</div>
                    : <div></div>
                }
                {/*//hlaska pri nacitavani dat//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

                <div>
                  

                  
                    {/*//delete dialog//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

                    <Dialog
                        open={open_delete}
                        onClose={handleClose_delete}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Chcete smazat ?"}
                        </DialogTitle>

                        <DialogActions>
                            <Button onClick={delete_item} >
                                Ano
                            </Button>
                            <Button onClick={handleClose_delete} >
                                Ne
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/*//delete dialog//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}



                    {/*//add + add dialog//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                    <Dialog
                        open={open_edit}
                        onClose={handleClose_edit}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {edit_dialog_header}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <MuiGrid container spacing={0.15}>
                                    <MuiGrid item xs={2.5} >
                                        <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Pořadí</Box>
                                    </MuiGrid>
                                    <MuiGrid item xs={3}>

                                        <TextField
                                            margin="normal"
                                            value={vyrrozpisData.poradie}
                                            label="pořadí"
                                            size="small"
                                            type="number"
                                            name="poradie"
                                            onChange={handleChange}


                                        />
                                    </MuiGrid>
                                    <MuiGrid item xs={6.5} >
                                        <Box sx={{ mt: 3, ml: 0.5 }}><div></div></Box>
                                    </MuiGrid>
                                    <MuiGrid item xs={2.5} >
                                        <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Kod</Box>
                                    </MuiGrid>
                                    <MuiGrid item xs={3}>

                                        <TextField
                                            margin="normal"
                                            value={vyrrozpisData.znacka}
                                            label="značka"
                                            size="small"
                                            name="znacka"
                                            onChange={handleChange}


                                        />
                                    </MuiGrid>
                                    <MuiGrid item xs={6.5} >

                                        <Box sx={{ color: validationData.znacka_exist ? 'text.secondary' : "red", mt: 3, ml: 0.5 }}>   <div style={{ display: "flex", flexDirection: "row" }}><div hidden={validationData.znacka_exist}><ErrorIcon /></div><div>{validationData.znacka_nazov}</div></div></Box>
                                    </MuiGrid>


                                </MuiGrid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { handleClose_edit() }}>
                                <CancelIcon /> {" "} Zrušiť
                            </Button>

                            <Button disabled={(!validationData.znacka_exist)} onClick={() => { handleSaveClick() }}>
                                <SaveIcon /> {" "} Uložiť
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*//add + add dialog//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                    <StyledBox>
                        <DataGridPremium
                            localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                            apiRef={apiRef}
                            components={{
                                Toolbar: rows.length === 0 ? CustomToolbarEmpty : EditToolbar,
                                NoRowsOverlay
                            }}
                            hideFooter
                            disableColumnReorder
                            rows={rows}
                            columns={columns}
                            rowHeight={40}
                            initialState={{ pinnedColumns: { right: ['Edituj', 'Smaž'] } }}
                        />
                    </StyledBox>

                </div>
            </div>
            <ToastContainer autoClose={2}/>


        </div>
    );
}

export default def_vyrrozpisTable;
