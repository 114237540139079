import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../shared/Navbar";
import useWindowSize from "../../hooks/useWindow";
import * as urlHelp from "../../features/urlHelpers";
import {
  DataGridPremium,
  csCZ,
  GridEditRowsModel,
  GridColumns,
  GridCallbackDetails,
  GridCellParams,
  GridRowId,
  GridRowParams,
  GridToolbar,
  MuiBaseEvent,
  MuiEvent,
  visibleGridColumnsSelector,
  useGridApiRef,
  gridVisibleSortedRowIdsSelector,
  gridVisibleRowCountSelector,
  visibleGridColumnsLengthSelector,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridRowModelUpdate,
  GridRowsProp,
  GridEditCellValueParams,
  GridValueFormatterParams,
  GridEditCellPropsParams,
  GridCellEditCommitParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import axios from "axios";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
//import { RootState } from "../../redux/store";
import Moment from "moment";

//import { logoutUser } from "../../redux/actions/authActions";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { getLicense } from "../shared/license";
import { getRowStatus_isCorrect } from "../shared/search";
import { logout, log_or_not } from "../shared/authFunctions";
import FilterAltIcon from "@mui/icons-material/OpenInNew";
import { loginData } from "../auth/redux/loginSlice";
import { errorHandleDialog } from "../shared/errorDialog";
function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center"></Stack>
  );
}

function VyrobaRozpis() {
  const [Data, setData] = useState([] as GridRowsProp); //data do tabulky ziskane z backendu
  const [rows, setRows] = useState([] as GridRowsProp);
  const [rows_loaded, setRowsLoaded] = useState(false as boolean);
  const [searchValue, setSearchedValue] = useState("" as string);
  const { width } = useWindowSize();
  const success = (stlpec: string) =>
    toast.success("Položka byla upravena v " + stlpec);
  const error = (stlpec: string) =>
    toast.error(
      "Chyba při úpravě položky hodnoty nesmí být záporné číslo v " + stlpec
    );
  const errorDec = (stlpec: string) =>
    toast.error("Chyba při úpravě nesmí být desetinné číslo v " + stlpec);
  const apiRef = useGridApiRef();
  let pomocneData = [] as GridRowsProp;
  const navigate = useHistory();
  const dispatch = useDispatch();
  const logindata = useSelector(loginData);
  const [date_vyr, setDate_vyr] = useState("");
  const [date_exp, setDate_exp] = useState("");
  const [day_vyr, setDay_vyr] = useState("");
  const [day_exp, setDay_exp] = useState("");
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [open, setOpen] = React.useState(false);
  const [error_dialog, setError_dialog] = React.useState("");
  // connection ID pre notifikacie
  const [notificationID, setNotificationID] = useState("");
  LicenseInfo.setLicenseKey(getLicense());

  useEffect(() => {
    getVyrobaData();
  }, []);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(urlHelp.getNotificationUrl(logindata))
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          // ziskame notificationID ( kvoli update)
          connection.invoke("GetConnectionId").then(function (connectionID) {
            setNotificationID(connectionID);
          });

          connection.on("ReceiveMessage", (message) => {
            //console.log("SignalR ReceiveMessage ", message)
            if (
              message.data === "vyr_rozpis" &&
              message.typ === "refresh" &&
              message.datum_vyroba === urlHelp.get_datum_vyroba(logindata)
            ) {
              setRowsLoaded(false);
              getVyrobaData();
              //window.location.reload(false);
            }
          });
        })
        .catch((error) =>
          errorHandleDialog(error.response, navigate, dispatch)
        );
    }
  }, [connection]);

  const StyledBox = styled(Box)(({ theme }) => ({
    height: innerHeight - innerHeight / 4.5,
    maxHeight: rows.length === 0 ? 3 : rows.length * 25 + 75,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
    },
    "& .super-app-theme--header": {
      backgroundColor: "rgba(0,102,0,255)",
      color: "rgb(255, 255, 255)",
    },
    "& .super-app-theme--c": {
      backgroundColor: "rgba(255, 200, 0, 0.55)",
    },
  }));

  async function getVyrobaData() {
    log_or_not(logindata, navigate, dispatch);
    let i = 0;
    //console.log(vyrrozpisUrl + "?datum_vyroba=" + logindata.userInfo.ConfigData.datum_vyroba.slice(0, 10));
    await axios
      .get(
        urlHelp.vyrrozpisUrl +
          "?datum_vyroba=" +
          urlHelp.get_datum_vyroba(logindata),
        urlHelp.get_header(logindata)
      )
      .then(function (response) {
        const newData = response.data.data.map((obj: any) => ({
          ...obj,
          id: i++,
        }));
        console.log(newData);
        setData(newData);
        search_help(newData, searchValue);
        pomocneData = newData;

        setDate_vyr(response.data.datum_vyroba.slice(0, 10));
        setDate_exp(response.data.datum_expedice.slice(0, 10));
        setDay_vyr(response.data.den_vyroba);
        setDay_exp(response.data.den_expedice);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function Search(e: any) {
    e.target.focus;
    const value = e.target.value.toString().toLowerCase() as string;
    setSearchedValue(value);
    search(Data, value);
  }

  function search(data: GridRowsProp, search_value: string) {
    let i = 0;
    let result = [];
    while (i < data.length) {
      const columns = [
        data[i].nazov_pec,
        data[i].nazov_vyr,
        data[i].znacka_pec,
        data[i].znacka_vyr,
      ];
      if (getRowStatus_isCorrect(search_value, columns)) {
        result.push(data[i]);
      }
      i++;
    }
    setRows(result);
    setRowsLoaded(true);
  }

  function search_help(data: GridRowsProp, search_value: string) {
    let i = 0;

    let result = [];
    while (i < data.length) {
      const columns = [
        data[i].nazov_pec,
        data[i].nazov_vyr,
        data[i].znacka_pec,
        data[i].znacka_vyr,
      ];
      if (getRowStatus_isCorrect(search_value, columns)) {
        result.push(data[i]);
      }
      i++;
    }
    setRows(result);
    setRowsLoaded(true);
  }

  function isInt(n: any) {
    return n % 1 === 0;
  }

  const columns: GridColumns = [
    {
      field: "nazov_vyr",
      headerName: "Název výrobku",
      editable: false,
      width: width < 1000 ? 190 : 220,
      pinnable: true,
      renderCell: (params: any) => (
        <div>
          <a
            href={"/zvy/" + params.row.znacka_vyr}
            target="_blank"
            rel="noreferrer noopener"
          >
            <button style={{ width: 30, textAlign: "start", color: "gray" }}>
              <FilterAltIcon />
            </button>
          </a>
          {params.value}
        </div>
      ),
    },
    {
      field: "nazov_pec",
      headerName: "Pečivo",
      editable: false,
      width: width < 1000 ? 100 : 120,
      pinnable: true,
      headerAlign: "center",
    },
    {
      field: "znacka_pec",
      headerName: "Kód",
      editable: false,
      width: width < 1000 ? 100 : 120,
      pinnable: true,
      headerAlign: "center",
    },
    {
      field: "doVyroba",
      headerName: "Dovýroba",
      width: 100,
      type: "number",
      editable: false,
      pinnable: false,
      headerAlign: "center",
    },
    {
      field: "predvyroba",
      headerName: "Předvýroba",
      width: 100,
      type: "number",
      editable: false,
      pinnable: false,
      headerAlign: "center",
    },
    {
      field: "obj_pex",
      headerName: "Praha",
      width: 100,
      type: "number",
      editable: true,
      pinnable: false,
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value as number);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Praha");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "obj_krt_pex",
      headerName: "Praha krt",
      editable: false,
      pinnable: false,
      width: 100,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "obj_mex",
      headerName: "Brno",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: true,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value as number);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Brno");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "obj_zex",
      headerName: "Žiželice",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: false,
      pinnable: false,
    },
    {
      field: "obj_hex",
      headerName: "Maďarsko",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: true,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value as number);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Maďarsko");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "obj_krt_hex",
      headerName: "HU krt",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: false,
      pinnable: false,
    },
    {
      field: "obj_vex",
      headerName: "Rakousko",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: true,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value as number);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Rakousko");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "obj_krt_vex",
      headerName: "AT krt",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: false,
      pinnable: false,
    },
    {
      field: "znacka_vyr",
      cellClassName: "super-app-theme--c",
      headerName: "Značka",
      width: 120,
      headerAlign: "center",
      editable: false,
      pinnable: false,
    },
    {
      field: "znacka_celkom",
      cellClassName: "super-app-theme--c",
      headerName: "Celkem",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: false,
      pinnable: false,
    },

    {
      field: "sklad_pex",
      headerName: "Praha",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: true,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value as number);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Praha");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "sklad_korekce",
      headerName: "Korekce",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: true,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value as number);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Korekce");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "sklad_zex",
      headerName: "Žiželice",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: true,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value as number);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Žiželice");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "rozpis_cz",
      headerName: "DovCZ",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: false,
      pinnable: false,
    },
    {
      field: "rozpis_hu",
      headerName: "DovHU",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: false,
      pinnable: false,
    },
    {
      field: "rozpis_at",
      headerName: "DovAT",
      width: 100,
      type: "number",
      headerAlign: "center",
      editable: false,
      pinnable: false,
    },
  ];

  return (
    <div>
      <Navbar />
      <div style={{ marginLeft: "2%", marginRight: "2%", marginTop: "1%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#494529",
            minWidth: 279,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              color: "black",
              margin: "1%",
              backgroundColor: "transparent",
              borderRadius: 5,
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                borderColor: "white",
                borderWidth: 1,
                width: width >= 1000 ? "50%" : "100%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: width >= 600 ? 13 : 10,
                  flex: 1,
                  backgroundColor: "rgba(0,102,0,255)",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <div style={{ marginLeft: "5%", justifyContent: "center" }}>
                  Výroba
                </div>
              </div>

              <div
                style={{
                  color: "white",
                  fontSize: width >= 600 ? 13 : 10,
                  flex: 2,
                  textAlign: "center",
                  borderLeftWidth: 1,
                  borderLeftColor: "white",
                  justifyContent: "center",
                }}
              >
                <div>{Moment(date_vyr).format("DD.MM.YYYY")}</div>
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: width >= 600 ? 13 : 10,
                  flex: 0.5,
                  textAlign: "end",
                  marginRight: "1%",
                  justifyContent: "center",
                }}
              >
                <div>{day_vyr}</div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                borderColor: "white",
                borderBottomWidth: 1,
                borderRightWidth: 1,
                borderLeftWidth: 1,
                width: width >= 1000 ? "50%" : "100%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: width >= 600 ? 13 : 10,
                  flex: 1,
                  backgroundColor: "rgba(0,102,0,255)",
                }}
              >
                <div style={{ marginLeft: "5%", justifyContent: "center" }}>
                  Expedice
                </div>
              </div>

              <div
                style={{
                  color: "white",
                  fontSize: width >= 600 ? 13 : 10,
                  flex: 2,
                  textAlign: "center",
                  borderLeftWidth: 1,
                  borderLeftColor: "white",
                  justifyContent: "center",
                }}
              >
                <div>{Moment(date_exp).format("DD.MM.YYYY")}</div>
              </div>
              <div
                style={{
                  justifyContent: "center",
                  color: "white",
                  fontSize: width >= 600 ? 13 : 10,
                  flex: 0.5,
                  textAlign: "end",
                  marginRight: "1%",
                }}
              >
                <div>{day_exp}</div>
              </div>
            </div>
          </div>

          {width < 500 ? (
            <div
              style={{
                flex: 1,
                color: "white",
                margin: "1.5%",
                textAlign: "center",
              }}
            >
              <input
                style={{
                  backgroundColor: "transparent",
                  borderColor: "white",
                  borderWidth: 1,
                  borderRadius: 5,
                  width: "80%",
                  textAlign: "center",
                }}
                value={searchValue}
                onChange={Search}
                placeholder="Hledej"
              />
            </div>
          ) : (
            <div
              style={{
                flex: 1,
                color: "white",
                margin: "1.5%",
                textAlign: "center",
              }}
            >
              <input
                style={{
                  backgroundColor: "transparent",
                  borderColor: "white",
                  borderWidth: 1,
                  borderRadius: 5,
                  textAlign: "center",
                }}
                value={searchValue}
                onChange={Search}
                placeholder="Hledej"
              />
            </div>
          )}

          {width < 500 ? (
            <div
              style={{
                flex: 1,
                textAlign: "end",
                fontSize: 15,
                color: "white",
                margin: "1%",
                justifyContent: "center",
              }}
            >
              Výrobní plán
            </div>
          ) : (
            <div
              style={{
                flex: 1,
                textAlign: "end",
                fontSize: 22,
                color: "white",
                margin: "1%",
                justifyContent: "center",
              }}
            >
              Výrobní plán
            </div>
          )}
        </div>
        {rows.length === 0 && rows_loaded ? (
          <div style={{ textAlign: "center", justifyContent: "center" }}>
            Žádne záznamy
          </div>
        ) : (
          <div></div>
        )}
        {rows.length === 0 && !rows_loaded ? (
          <div style={{ textAlign: "center", justifyContent: "center" }}>
            Načítavám data
          </div>
        ) : (
          <div></div>
        )}
        <div>
          <StyledBox>
            <DataGridPremium
              localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
              apiRef={apiRef}
              components={{ NoRowsOverlay }}
              hideFooter
              //autoHeight
              disableColumnReorder
              rows={rows}
              columns={columns}
              rowHeight={25}
              initialState={{
                pinnedColumns: {
                  left: ["nazov_vyr", "nazov_pec", "znacka_pec"],
                },
              }}
              onCellEditCommit={async function (
                params: GridCellEditCommitParams,
                event: MuiEvent<MuiBaseEvent>,
                details: GridCallbackDetails
              ) {
                const row = rows.filter((row: any) => row.id === params.id);

                if (params.value !== null) {
                  await axios
                    .post(
                      urlHelp.vyrrozpisUrlUpdate,
                      {
                        datum_vyroba: urlHelp.get_datum_vyroba(logindata),
                        field_name: params.field,
                        field_value: params.value,
                        rowdata: row[0],
                        notificationID: notificationID,
                      },
                      urlHelp.get_header(logindata)
                    )
                    .then(function (response) {
                      success(params.field);
                      apiRef.current.updateRows([
                        { id: params.id, [params.field]: params.value },
                      ]);

                      let i = 0;
                      while (i < Data.length) {
                        if (Data[i].id === params.id) {
                          Data[i][params.field] = params.value;
                          break;
                        }
                        i++;
                      }
                    })
                    .catch(function (error) {
                      errorHandleDialog(error.response, navigate, dispatch);
                    });
                }
              }}
            />
          </StyledBox>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default VyrobaRozpis;
