import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../shared/Navbar";
import useWindowSize from "../../hooks/useWindow";
import * as urlHelp from "../../features/urlHelpers";
import {
  DataGridPremium,
  csCZ,
  GridColumns,
  GridCallbackDetails,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
  GridRowsProp,
  GridValueFormatterParams,
  GridCellEditCommitParams,
} from "@mui/x-data-grid-premium";
import axios from "axios";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { getLicense } from "../shared/license";
import { getRowStatus_isCorrect } from "../shared/search";
import { logout, log_or_not } from "../shared/authFunctions";
import { loginData } from "../auth/redux/loginSlice";
import { errorHandleDialog } from "../shared/errorDialog";
import styles from "./PecivoTable.module.sass";
import remove_accents from "remove-accents";

function PecivoTable() {
  const [pecivoData, setPecivoData] = useState([] as GridRowsProp); //data do tabulky ziskane z backendu
  const [rows, setRows] = useState([] as GridRowsProp);
  const [rows_loaded, setRowsLoaded] = useState(false as boolean);
  const dispatch = useDispatch();
  const [searchValue, setSearchedValue] = useState("" as string);
  const success = (stlpec: string) =>
    toast.success("Položka byla upravena v " + stlpec + "!");
  const error = (stlpec: string) =>
    toast.error(
      "Chyba při úpravě položky hodnoty nesmí být zápornéani desetinné číslo v " +
        stlpec +
        "!"
    );
  const apiRef = useGridApiRef();
  const navigate = useHistory();
  const logindata = useSelector(loginData);
  const [connection, setConnection] = useState<null | HubConnection>(null);

  // connection ID pre notifikacie
  const [notificationID, setNotificationID] = useState("");
  LicenseInfo.setLicenseKey(getLicense());

  useEffect(() => {
    setRowsLoaded(false);
    getPecivoData();
  }, []);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(urlHelp.getNotificationUrl(logindata))
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          //ziskame notificationID ( kvoli update)
          connection.invoke("GetConnectionId").then(function (connectionID) {
            setNotificationID(connectionID);
          });

          connection.on("ReceiveMessage", (message) => {
            //console.log("SignalR ReceiveMessage ", message)
            if (message.data === "pecivo" && message.typ === "refresh") {
              setRowsLoaded(false);
              getPecivoData();
              //window.location.reload(false);
            }
          });
        })
        .catch((error) =>
          errorHandleDialog(error.response, navigate, dispatch)
        );
    }
  }, [connection]);

  const styleTable = {
    height: window.innerHeight - 210.8,
    width: "100%",
    margin: 0,
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
    },
    "& .super-app-theme--header": {
      backgroundColor: "rgba(0,102,0,255)",
      color: "rgb(255, 255, 255)",
    },
    "& .MuiDataGrid-colCell:scroll": {
      outline: "none",
    },
  };

  async function getPecivoData() {
    log_or_not(logindata, navigate, dispatch);
    await axios
      .get(
        urlHelp.pecivoUrl +
          "?datum_vyroba=" +
          urlHelp.get_datum_vyroba(logindata),
        urlHelp.get_header(logindata)
      )
      .then(function (response) {
        const newData = response.data.data;
        console.log(newData);
        setPecivoData(newData);
        setRows(newData);
        setRowsLoaded(true);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function isInt(n: any) {
    return n % 1 === 0;
  }

  const columns: GridColumns = [
    {
      field: "znacka",
      headerName: "Kód suroviny",
      editable: false,
      width: 180,
      pinnable: false,
      headerAlign: "center",
    },
    {
      field: "nazov",
      headerName: " Název suroviny",
      editable: false,
      width: 295,
      pinnable: false,
      headerAlign: "center",
    },
    {
      field: "mj",
      headerName: "MJ",
      type: "number",
      editable: false,
      width: 80,
      pinnable: false,
      headerAlign: "center",
    },
    {
      field: "kategoria",
      headerName: "Kategorie",
      headerAlign: "center",
      width: 160,
      editable: true,
      pinnable: false,
    },
    {
      field: "prokroj",
      headerName: "Prokroj",
      type: "number",
      headerAlign: "center",
      width: 160,
      editable: true,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        let value = "";
        const valueFormatted = Number(params.value as number);

        value = valueFormatted.toFixed(0).replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Prokroj");
        }
        return { ...params.props, error: hasError };
      },
    },

    {
      field: "bochniky",
      headerName: "Bochníky",
      type: "number",
      headerAlign: "center",
      width: 160,
      editable: true,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        let value = "";
        const valueFormatted = Number(params.value as number);

        if (
          valueFormatted.toString().length > 3 &&
          valueFormatted.toString()[valueFormatted.toString().length - 2] ===
            "."
        ) {
          value = valueFormatted
            .toFixed(1)
            .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
        } else if (
          valueFormatted.toString().length > 3 &&
          valueFormatted.toString()[valueFormatted.toString().length - 3] ===
            "."
        ) {
          value = valueFormatted
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
        } else {
          value = valueFormatted
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");
        }
        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0;
        if (hasError) {
          error("Bochníky");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "stroj",
      headerName: "Stroj",
      headerAlign: "center",
      width: 160,
      editable: true,
      pinnable: false,
    },
    {
      field: "vykon",
      headerName: "Výkon",
      type: "number",
      headerAlign: "center",
      width: 160,
      editable: true,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value as number);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Výkon");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "poradie",
      headerName: "Pořadí",
      type: "number",
      headerAlign: "center",
      width: 160,
      editable: true,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        const valueFormatted = Number(params.value as number);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Pořadí");
        }
        return { ...params.props, error: hasError };
      },
    },
  ];

  return (
    <div>
      <Navbar />

      <div
        style={{
          height: window.innerHeight - 70.8,
        }}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <input
              onChange={(e) => {
                console.log(e.target.value);
                setSearchedValue(e.target.value);
              }}
              className={styles.input}
              placeholder="Hledej"
            ></input>
            <div className={styles.title}>Pečivo</div>
          </div>
          <div className={styles.grid}>
            {rows_loaded ? (
              <DataGridPremium
                rows={rows.filter(
                  (row) =>
                    String(
                      remove_accents(row.znacka ? row.znacka.toUpperCase() : "")
                    ).includes(remove_accents(searchValue.toUpperCase())) ||
                    String(
                      remove_accents(row.nazov ? row.nazov.toUpperCase() : "")
                    ).includes(remove_accents(searchValue.toUpperCase())) ||
                    String(
                      remove_accents(
                        row.kategoria ? row.kategoria.toUpperCase() : ""
                      )
                    ).includes(remove_accents(searchValue.toUpperCase())) ||
                    String(
                      remove_accents(row.stroj ? row.stroj.toUpperCase() : "")
                    ).includes(remove_accents(searchValue.toUpperCase()))
                )}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                apiRef={apiRef}
                hideFooter
                columns={columns}
                rowHeight={25}
                sx={styleTable}
                //onEditRowsModelChange={handleEditRowsModelChange}
                disableColumnReorder
                onCellEditCommit={async function (
                  params: GridCellEditCommitParams,
                  event: MuiEvent<MuiBaseEvent>
                ) {
                  event.defaultMuiPrevented = true;
                  const row = rows.filter((row: any) => row.id === params.id);

                  if (params.value !== null) {
                    await axios
                      .post(
                        urlHelp.pecivoUrlUpdate,
                        {
                          field_name: params.field,
                          field_value: params.value,
                          rowdata: row[0],
                          notificationID: notificationID,
                        },
                        urlHelp.get_header(logindata)
                      )
                      .then(function (response) {
                        console.log("tutu som")
                        let index = rows.indexOf(row[0]);
                        let data = [...rows];
                        let row_copy = { ...row[0] };
                        row_copy[params.field] = params.value;
                        data[index] = row_copy;
                        setRows(data);
                        success(params.field);
                        // apiRef.current.updateRows([
                        //   { id: params.id, [params.field]: params.value },
                        // ]);

                        // let i = 0;
                        // while (i < pecivoData.length) {
                        //   if (pecivoData[i].id === params.id) {
                        //     pecivoData[i][params.field] = params.value;
                        //     break;
                        //   }
                        //   i++;
                        // }
                      })
                      .catch(function (error) {
                        errorHandleDialog(error.response, navigate, dispatch);
                      });
                  }
                }}
                initialState={{ pinnedColumns: { left: ["id"] } }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2} />
    </div>
  );
}

export default PecivoTable;
