import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Error404 from "./pages/Error404";
import "./styles/app.sass";
import Loginpage from "./components/auth/LoginForm";
import Menupage from "./components/menu/MenuInfo";
import DatumVyrobaForm from "./components/production/DatumVyrobaForm";
import DefiniceKartonuTable from "./components/production/DefiniceKartonuTable";
import Definice_vyrrozpisTable from "./components/production/Definice_vyrrozpisTable";
import KartonyRozpisTable from "./components/production/KartonyRozpisTable";
import PecivoTable from "./components/production/PecivoTable";
import UzivatelTable from "./components/production/UzivatelTable";
import VyrobaRozpisTable from "./components/production/VyrobaRozpisTable";
import PrahaTable from "./components/production/PrahaTable";
import ZpeTable from "./components/production/ZpeTable";
import ZvyTable from "./components/production/ZvyTable";
import ZtpTable from "./components/production/ZtpTable";
import ChybaNacitania from "./pages/Error405";
import App from "./components/shared/Navbar";

function Apps() {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Loginpage />} />
        <Route exact path="/menu" render={() => <Menupage />} />
        <Route exact path="/skladPraha" render={() => <PrahaTable />} />
        <Route exact path="/ztp" render={() => <ZtpTable />} />
        <Route exact path="/zvy/:znacka?" render={() => <ZvyTable />} />
        <Route exact path="/zpe" render={() => <ZpeTable />} />
        <Route exact path="/pecivo" render={() => <PecivoTable />} />
        <Route exact path="/uzivatel" render={() => <UzivatelTable />} />
        <Route
          exact
          path="/definice_vyrrozpis"
          render={() => <Definice_vyrrozpisTable />}
        />
        <Route
          exact
          path="/definiceKartonu"
          render={() => <DefiniceKartonuTable />}
        />
        <Route exact path="/datumVyroby" render={() => <DatumVyrobaForm />} />
        <Route
          exact
          path="/kartonyRozpis"
          render={() => <KartonyRozpisTable />}
        />
        <Route
          exact
          path="/vyrobaRozpis"
          render={() => <VyrobaRozpisTable />}
        />
        <Route exact path="/chybaNacitania" render={() => <ChybaNacitania />} />
        <Route render={() => <Error404 />} />
      </Switch>
    </Router>
  );
}

export default Apps;
