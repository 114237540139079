// import React, { useState, useEffect } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import Navbar from "../shared/Navbar";
// import * as urlHelp from "../../features/urlHelpers";
// import {
//   DataGridPremium,
//   csCZ,
//   GridEditRowsModel,
//   GridColumns,
//   GridCallbackDetails,
//   GridCellParams,
//   GridRowId,
//   GridRowParams,
//   GridToolbar,
//   MuiBaseEvent,
//   MuiEvent,
//   visibleGridColumnsSelector,
//   useGridApiRef,
//   gridVisibleSortedRowIdsSelector,
//   gridVisibleRowCountSelector,
//   visibleGridColumnsLengthSelector,
//   GridToolbarFilterButton,
//   GridToolbarDensitySelector,
//   GridRowModelUpdate,
//   GridRowsProp,
//   GridValueFormatterParams,
//   GridCellEditCommitParams,
//   GridToolbarContainer,
//   GridToolbarExport,
// } from "@mui/x-data-grid-premium";
// import axios from "axios";
// import { LicenseInfo } from "@mui/x-data-grid-premium";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import { useHistory } from "react-router-dom";
// import Stack from "@mui/material/Stack";
// import Moment from "moment";
// import { useSelector, useDispatch } from "react-redux";
// //import { RootState } from "../../redux/store";
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from "@mui/material";
// //import { logoutUser } from "../../redux/actions/authActions";
// import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
// import { getLicense } from "../shared/license";
// import { getRowStatus_isCorrect } from "../shared/search";
// import { logout, log_or_not } from "../shared/authFunctions";
// import { loginData } from "../auth/redux/loginSlice";
// import { errorHandleDialog } from "../shared/errorDialog";
// //štýlovanie pre tabuľku

// function NoRowsOverlay() {
//   return (
//     <Stack height="100%" alignItems="center" justifyContent="center"></Stack>
//   );
// }

// function KartonyRozpisTable() {
//   const apiRef = useGridApiRef(); //referencia na tabuĺku
//   const [rows, setRows] = useState([] as GridRowsProp); // riadky tabu%lky
//   const [rows_loaded, setRowsLoaded] = useState(false as boolean);
//   const [kartonyRozpisData, setKartonyRozpisData] = useState(
//     [] as GridRowsProp
//   ); // riadky tabuľky využite na search drzia sa tam vsetky riadky
//   const success = (stlpec: string) =>
//     toast.success("Položka byla upravena v " + stlpec + "!");
//   const error = (stlpec: string) =>
//     toast.error(
//       "Chyba hodnoty nesmí být záporné ani desetinné číslo v " + stlpec + "!"
//     );
//   const [searchValue, setSearchedValue] = useState("" as string);
//   const navigate = useHistory();
//   const dispatch = useDispatch();
//   const [date_vyr, setDate_vyr] = useState("");
//   const [date_exp, setDate_exp] = useState("");
//   const [day_vyr, setDay_vyr] = useState("");
//   const [day_exp, setDay_exp] = useState("");
//   const [date, setDate] = useState("");
//   const logindata = useSelector(loginData);
//   const [connection, setConnection] = useState<null | HubConnection>(null);
//   const [open, setOpen] = React.useState(false);
//   const [error_dialog, setError_dialog] = React.useState("");
//   // connection ID pre notifikacie
//   const [notificationID, setNotificationID] = useState("");
//   LicenseInfo.setLicenseKey(getLicense());

//   useEffect(() => {
//     console.log(getLicense())
//     getKartonyRozpisData();
//   }, []);

//   useEffect(() => {
//     const connect = new HubConnectionBuilder()
//       .withUrl(urlHelp.getNotificationUrl(logindata))
//       .withAutomaticReconnect()
//       .build();

//     setConnection(connect);
//   }, []);

//   useEffect(() => {
//     if (connection) {
//       connection
//         .start()
//         .then(() => {
//           // ziskame notificationID ( kvoli update)
//           connection.invoke("GetConnectionId").then(function (connectionID) {
//             setNotificationID(connectionID);
//           });

//           connection.on("ReceiveMessage", (message) => {
//             console.log("SignalR ReceiveMessage ", message);
//             if (
//               message.data === "kartony_rozpis" &&
//               message.typ === "refresh" &&
//               message.datum_vyroba === urlHelp.get_datum_vyroba(logindata)
//             ) {
//               window.location.reload(false);
//             }
//           });
//         })
//         .catch((error) => errorHandleDialog(error.response, navigate, dispatch));
//     }
//   }, [connection]);

//   function CustomToolbar() {
//     return (
//       <div>
//         <GridToolbarContainer>
//           <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
//             <div
//               style={{ flex: 1, textAlign: "start", justifyContent: "center" }}
//             >
//               <GridToolbarExport
//                 printOptions={{
//                   hideFooter: true,
//                 }}
//               />
//             </div>

//             <div style={{ textAlign: "end", flex: 1, margin: "0.8%" }}>
//               Výroba: {Moment(date).format("DD.MM.YYYY")} Výrobní plán - kartony
//             </div>
//           </div>
//         </GridToolbarContainer>
//       </div>
//     );
//   }

//   function CustomToolbarEmpty() {
//     return <div></div>;
//   }

//   const StyledBox = styled(Box)(({ theme }) => ({
//     height: innerHeight - innerHeight / 4.5,
//     maxHeight: rows.length === 1 ? 3 : rows.length * 25 + 100,
//     width: "100%",
//     alignItems: "center",
//     justifyContent: "center",
//     "& .MuiDataGrid-cell--editable": {
//       backgroundColor: "rgb(195, 247, 195)",
//       "&:hover": {
//         backgroundColor: "rgb(240,250,190)",
//       },
//     },
//     "& .super-app-theme--1": {
//       backgroundColor: "rgba(0,102,0,255)",
//       color: "rgb(255, 255, 255)",
//       "&:hover": {
//         color: "rgb(0,0,0)",
//       },
//     },
//     "& .super-app-theme--2": {
//       backgroundColor: "rgb(73, 69, 41)",
//       color: "rgb(255, 255, 255)",
//       "&:hover": {
//         color: "rgb(0,0,0)",
//       },
//     },
//     "& .super-app-theme--header": {
//       backgroundColor: "rgba(0,102,0,255)",
//       color: "rgb(255, 255, 255)",
//     },
//     //' .table-cell-trucate': {
//     //    overflow: 'hidden',
//     //    textOverflow: 'ellipsis',
//     //    whiteSpace: 'nowrap',
//     //},
//   }));

//   // get dat z backendu nastavia sa aj rovs aj pomocne data aj data ktore drzia vzdy vsetky riadky
//   async function getKartonyRozpisData() {
//     log_or_not(logindata, navigate, dispatch);
//     let i = 0;
//     await axios
//       .get(
//         urlHelp.kartonyRozpisUrl +
//           "?datum_vyroba=" +
//           urlHelp.get_datum_vyroba(logindata),
//         urlHelp.get_header(logindata)
//       )
//       .then(function (response) {
//         var newData = response.data.data.map((obj: any) => ({
//           ...obj,
//           id: i++,
//         })); // do row sa prida id od 0
//         console.log(response.data.data);
//         setKartonyRozpisData(newData);
//         //search_help(newData, searchValue);
//         setRows(newData)
//         setDate(urlHelp.get_datum_vyroba(logindata).slice(0, 10));
//         setDate_vyr(response.data.datum_vyroba.slice(0, 10));
//         setDate_exp(response.data.datum_expedice.slice(0, 10));
//         setDay_vyr(response.data.den_vyroba);
//         setDay_exp(response.data.den_expedice);
//       })
//       .catch(function (error) {
//         errorHandleDialog(error.response, navigate, dispatch)
//       });
//   }

//   function search(data: GridRowsProp, search_value: string) {
//     let i = 0;
//     let result = [];
//     let value_nazov_ = 0;
//     let value_znacka_cz_ = 0;
//     let value_znacka_hu_ = 0;
//     let value_znacka_at_ = 0;
//     let value_znacka_pl_ = 0;
//     let header = null;
//     while (i < data.length) {
//       const columns = [
//         data[i].nazov,
//         data[i].odberatel_cz,
//         data[i].odberatel_hu,
//         data[i].odberatel_at,
//         data[i].odberatel_pl,
//       ];
//       if (data[i].typ === 2) {
//         result.push(data[i]);
//         i++;
//         continue;
//       }
//       if (data[i].typ === 1) {
//         header = data[i];
//         i++;
//         continue;
//       }
//       if (getRowStatus_isCorrect(search_value, columns)) {
//         value_nazov_ = data[i].mnozstvo_sum + value_nazov_;
//         value_znacka_cz_ = data[i].mnozstvo_cz + value_znacka_cz_;
//         value_znacka_hu_ = data[i].mnozstvo_hu + value_znacka_hu_;
//         value_znacka_at_ = data[i].mnozstvo_at + value_znacka_at_;
//         value_znacka_pl_ = data[i].mnozstvo_pl + value_znacka_pl_;
//         if (header !== null) {
//           result.push(header);
//           header = null;
//         }
//         result.push(data[i]);
//       }
//       i++;
//     }
//     result[result.length - 1].mnozstvo_sum = value_nazov_;
//     result[result.length - 1].mnozstvo_cz = value_znacka_cz_;
//     result[result.length - 1].mnozstvo_hu = value_znacka_hu_;
//     result[result.length - 1].mnozstvo_at = value_znacka_at_;
//     result[result.length - 1].mnozstvo_pl = value_znacka_pl_;
//     setRows(result);
//     setRowsLoaded(true);
//   }

//   function search_help(data: GridRowsProp, search_value: string) {
//     let new_id = "";
//     let i = 0;
//     let j = 0;
//     let result = [];
//     let value_nazov_ = 0;
//     let value_znacka_cz_ = 0;
//     let value_znacka_hu_ = 0;
//     let value_znacka_at_ = 0;
//     let value_znacka_pl_ = 0;
//     let header = null;
//     while (i < data.length) {
//       const columns = [
//         data[i].nazov,
//         data[i].odberatel_cz,
//         data[i].odberatel_hu,
//         data[i].odberatel_at,
//         data[i].odberatel_pl,
//       ];
//       if (data[i].typ === 2) {
//         result.push(data[i]);
//         i++;
//         continue;
//       }
//       if (data[i].typ === 1) {
//         header = data[i];
//         i++;
//         continue;
//       }
//       if (getRowStatus_isCorrect(search_value, columns)) {
//         value_nazov_ = data[i].mnozstvo_sum + value_nazov_;
//         value_znacka_cz_ = data[i].mnozstvo_cz + value_znacka_cz_;
//         value_znacka_hu_ = data[i].mnozstvo_hu + value_znacka_hu_;
//         value_znacka_at_ = data[i].mnozstvo_at + value_znacka_at_;
//         value_znacka_pl_ = data[i].mnozstvo_pl + value_znacka_pl_;
//         if (header !== null) {
//           result.push(header);
//           header = null;
//         }
//         result.push(data[i]);
//       }
//       i++;
//     }
//     result[result.length - 1].mnozstvo_sum = value_nazov_;
//     result[result.length - 1].mnozstvo_cz = value_znacka_cz_;
//     result[result.length - 1].mnozstvo_hu = value_znacka_hu_;
//     result[result.length - 1].mnozstvo_at = value_znacka_at_;
//     result[result.length - 1].mnozstvo_pl = value_znacka_pl_;
//     setRows(result);
//     setRowsLoaded(true);
//   }

//   function isInt(n: any) {
//     return n % 1 === 0;
//   }

//   function Search(e: any) {
//     const value = e.target.value.toString().toLowerCase();
//     setSearchedValue(value);
//     search(kartonyRozpisData, value);
//   }

//   // nastavenie stlpcov
//   const columns: GridColumns = [
//     {
//       field: "nazov",
//       headerName: "Kartonový výrobek",
//       editable: false,
//       width: 320,
//       pinnable: false,
//       sortable: false,
//     },
//     {
//       field: "znacka_cz",
//       headerName: "CZ",
//       type: "number",
//       editable: false,
//       width: 130,
//       pinnable: false,
//       sortable: false,
//       headerAlign: "center",
//     },
//     {
//       field: "mnozstvo_cz",
//       headerName: "Kartony CZ",
//       editable: true,
//       width: 130,
//       type: "number",
//       pinnable: false,
//       sortable: false,
//       headerAlign: "center",
//       valueFormatter: (params: GridValueFormatterParams) => {
//         if (params.value === null) return;
//         const valueFormatted = Number(params.value as number);

//         const value = valueFormatted
//           .toFixed(0)
//           .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

//         return `${value}`;
//       },
//       preProcessEditCellProps: (params: any) => {
//         const hasError = params.props.value < 0 || !isInt(params.props.value);
//         if (hasError) {
//           error("Kartony CZ");
//         }
//         return { ...params.props, error: hasError };
//       },
//     },
//     {
//       field: "znacka_hu",
//       headerName: "HU",
//       editable: false,
//       width: 130,
//       pinnable: false,
//       sortable: false,
//       headerAlign: "center",
//     },

//     {
//       field: "mnozstvo_hu",
//       headerName: "Kartony HU",
//       width: 130,
//       type: "number",
//       editable: true,
//       pinnable: false,
//       sortable: false,
//       headerAlign: "center",
//       valueFormatter: (params: GridValueFormatterParams) => {
//         if (params.value === null) return;
//         const valueFormatted = Number(params.value as number);

//         const value = valueFormatted
//           .toFixed(0)
//           .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

//         return `${value}`;
//       },
//       preProcessEditCellProps: (params: any) => {
//         const hasError = params.props.value < 0 || !isInt(params.props.value);
//         if (hasError) {
//           error("Kartony HU");
//         }
//         return { ...params.props, error: hasError };
//       },
//     },
//     {
//       field: "znacka_at",
//       headerName: "AT",
//       width: 130,
//       editable: false,
//       pinnable: false,
//       sortable: false,
//       headerAlign: "center",
//       //renderCell: (params: any) => (
//       //    <TableCell>
//       //        <Tooltip title="adam" >
//       //            <div className="table-cell-trucate">"aaaaa"</div>
//       //        </Tooltip>
//       //    </TableCell>
//       //),
//     },
//     {
//       field: "mnozstvo_at",
//       headerName: "Kartony AT",
//       width: 130,
//       type: "number",
//       editable: true,
//       pinnable: false,
//       sortable: false,
//       headerAlign: "center",
//       valueFormatter: (params: GridValueFormatterParams) => {
//         if (params.value === null) return;
//         const valueFormatted = Number(params.value as number);

//         const value = valueFormatted
//           .toFixed(0)
//           .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

//         return `${value}`;
//       },
//       preProcessEditCellProps: (params: any) => {
//         const hasError = params.props.value < 0 || !isInt(params.props.value);
//         if (hasError) {
//           error("Kartony AT");
//         }
//         return { ...params.props, error: hasError };
//       },
//     },
//     {
//       field: "znacka_pl",
//       headerName: "PL",
//       width: 130,
//       editable: false,
//       pinnable: false,
//       sortable: false,
//       headerAlign: "center",
//     },
//     {
//       field: "mnozstvo_pl",
//       headerName: "Kartony PL",
//       width: 130,
//       type: "number",
//       editable: true,
//       pinnable: false,
//       sortable: false,
//       headerAlign: "center",
//       valueFormatter: (params: GridValueFormatterParams) => {
//         if (params.value === null) return;
//         const valueFormatted = Number(params.value as number);

//         const value = valueFormatted
//           .toFixed(0)
//           .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

//         return `${value}`;
//       },
//       preProcessEditCellProps: (params: any) => {
//         const hasError = params.props.value < 0 || !isInt(params.props.value);
//         if (hasError) {
//           error("Kartony PL");
//         }
//         return { ...params.props, error: hasError };
//       },
//     },
//     {
//       field: "mnozstvo_sum",
//       headerName: "Kartony",
//       width: 130,
//       type: "number",
//       editable: false,
//       pinnable: false,
//       sortable: false,
//       headerAlign: "center",
//       valueFormatter: (params: GridValueFormatterParams) => {
//         if (params.value === null) return;
//         const valueFormatted = Number(params.value as number);

//         const value = valueFormatted
//           .toFixed(0)
//           .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

//         return `${value}`;
//       },
//     },
//   ];

//   return (
//     <div>
//       <Navbar />

//       <div
//         style={{
//           marginLeft: innerWidth >= 1025 ? "10%" : "2%",
//           marginRight: innerWidth >= 1025 ? "10%" : "2%",
//           minWidth: 279,
//           marginTop: "1%",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             backgroundColor: "#494529",
//           }}
//         >
//           <div
//             style={{
//               flex: 1,
//               display: "flex",
//               flexDirection: "column",
//               color: "black",
//               margin: "1%",
//               backgroundColor: "transparent",
//               borderRadius: 5,
//             }}
//           >
//             <div
//               style={{
//                 flex: 1,
//                 display: "flex",
//                 flexDirection: "row",
//                 borderColor: "white",
//                 borderWidth: 1,
//                 width: innerWidth >= 1000 ? "50%" : "100%",
//                 justifyContent: "center",
//               }}
//             >
//               <div
//                 style={{
//                   color: "white",
//                   fontSize: innerWidth >= 600 ? 13 : 10,
//                   flex: 1,
//                   backgroundColor: "rgba(0,102,0,255)",
//                   justifyContent: "center",
//                   height: "100%",
//                 }}
//               >
//                 <div style={{ marginLeft: "5%", justifyContent: "center" }}>
//                   Výroba
//                 </div>
//               </div>

//               <div
//                 style={{
//                   color: "white",
//                   fontSize: innerWidth >= 600 ? 13 : 10,
//                   flex: 2,
//                   textAlign: "center",
//                   borderLeftWidth: 1,
//                   borderLeftColor: "white",
//                   justifyContent: "center",
//                 }}
//               >
//                 <div>{Moment(date_vyr).format("DD.MM.YYYY")}</div>
//               </div>
//               <div
//                 style={{
//                   color: "white",
//                   fontSize: innerWidth >= 600 ? 13 : 10,
//                   flex: 0.5,
//                   textAlign: "end",
//                   marginRight: "1%",
//                   justifyContent: "center",
//                 }}
//               >
//                 <div>{day_vyr}</div>
//               </div>
//             </div>
//             <div
//               style={{
//                 flex: 1,
//                 display: "flex",
//                 flexDirection: "row",
//                 borderColor: "white",
//                 borderBottomWidth: 1,
//                 borderRightWidth: 1,
//                 borderLeftWidth: 1,
//                 width: innerWidth >= 1000 ? "50%" : "100%",
//                 justifyContent: "center",
//               }}
//             >
//               <div
//                 style={{
//                   color: "white",
//                   fontSize: innerWidth >= 600 ? 13 : 10,
//                   flex: 1,
//                   backgroundColor: "rgba(0,102,0,255)",
//                 }}
//               >
//                 <div style={{ marginLeft: "5%", justifyContent: "center" }}>
//                   Expedice
//                 </div>
//               </div>

//               <div
//                 style={{
//                   color: "white",
//                   fontSize: innerWidth >= 600 ? 13 : 10,
//                   flex: 2,
//                   textAlign: "center",
//                   borderLeftWidth: 1,
//                   borderLeftColor: "white",
//                   justifyContent: "center",
//                 }}
//               >
//                 <div>{Moment(date_exp).format("DD.MM.YYYY")}</div>
//               </div>
//               <div
//                 style={{
//                   justifyContent: "center",
//                   color: "white",
//                   fontSize: innerWidth >= 600 ? 13 : 10,
//                   flex: 0.5,
//                   textAlign: "end",
//                   marginRight: "1%",
//                 }}
//               >
//                 <div>{day_exp}</div>
//               </div>
//             </div>
//           </div>

//           {innerWidth < 500 ? (
//             <div
//               style={{
//                 flex: 1,
//                 color: "white",
//                 margin: "1.5%",
//                 textAlign: "center",
//               }}
//             >
//               <input
//                 style={{
//                   backgroundColor: "transparent",
//                   borderColor: "white",
//                   borderWidth: 1,
//                   borderRadius: 5,
//                   width: "80%",
//                   textAlign: "center",
//                 }}
//                 value={searchValue}
//                 onChange={Search}
//                 placeholder="Hledej"
//               />
//             </div>
//           ) : (
//             <div
//               style={{
//                 flex: 1,
//                 color: "white",
//                 margin: "1.5%",
//                 textAlign: "center",
//               }}
//             >
//               <input
//                 style={{
//                   backgroundColor: "transparent",
//                   borderColor: "white",
//                   borderWidth: 1,
//                   borderRadius: 5,
//                   textAlign: "center",
//                 }}
//                 value={searchValue}
//                 onChange={Search}
//                 placeholder="Hledej"
//               />
//             </div>
//           )}

//           {innerWidth < 950 ? (
//             <div
//               style={{
//                 flex: 1,
//                 textAlign: "end",
//                 fontSize: 14,
//                 color: "white",
//                 margin: "1%",
//                 justifyContent: "center",
//               }}
//             >
//               Výrobní plán - kartony
//             </div>
//           ) : (
//             <div
//               style={{
//                 flex: 1,
//                 textAlign: "end",
//                 fontSize: 22,
//                 color: "white",
//                 margin: "1%",
//                 justifyContent: "center",
//               }}
//             >
//               Výrobní plán - kartony
//             </div>
//           )}
//         </div>
//         {rows.length === 1 && rows_loaded ? (
//           <div style={{ textAlign: "center", justifyContent: "center" }}>
//             Žádne záznamy
//           </div>
//         ) : (
//           <div></div>
//         )}
//         {rows.length === 0 && !rows_loaded ? (
//           <div style={{ textAlign: "center", justifyContent: "center" }}>
//             Načítavám data
//           </div>
//         ) : (
//           <div></div>
//         )}
//         <div>

//           <StyledBox>
//             <DataGridPremium
//               disableColumnMenu={true}
//               components={{
//                 NoRowsOverlay,
//                 Toolbar: rows.length === 1 ? CustomToolbarEmpty : CustomToolbar,
//               }}
//               localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
//               apiRef={apiRef}
//               hideFooter
//               rows={rows}
//               columns={columns}
//               rowHeight={25}
//               disableSelectionOnClick
//               disableColumnReorder
//               getRowClassName={(params: any) =>
//                 `super-app-theme--${params.row.typ}`
//               }
//               onCellEditCommit={async function (
//                 params: GridCellEditCommitParams,
//                 event: MuiEvent<MuiBaseEvent>,
//                 details: GridCallbackDetails
//               ) {
//                 const row = rows.filter((row: any) => row.id === params.id);
//                 if (params.value !== null) {
//                   await axios
//                     .post(
//                       urlHelp.kartonyRozpisUrlUpdate,
//                       {
//                         datum_vyroba: urlHelp.get_datum_vyroba(logindata),
//                         field_name: params.field,
//                         field_value: params.value,
//                         rowdata: row[0],
//                         notificationID: notificationID,
//                       },
//                       urlHelp.get_header(logindata)
//                     )
//                     .then(function (response) {
//                       success(params.field);

//                       apiRef.current.updateRows([
//                         { id: params.id, [params.field]: params.value },
//                       ]);

//                       let i = 0;
//                       while (i < kartonyRozpisData.length) {
//                         if (kartonyRozpisData[i].id === params.id) {
//                           kartonyRozpisData[i][params.field] = params.value;
//                           break;
//                         }
//                         i++;
//                       }
//                     })
//                     .catch(function (error) {
//                       errorHandleDialog(error.response, navigate, dispatch)
//                     });
//                 }
//               }}
//               isCellEditable={(params: any) => {
//                 if (params.row.typ === 2) return false;
//                 if (
//                   (params.field === "mnozstvo_cz" &&
//                     params.row.mnozstvo_cz !== null) ||
//                   (params.field === "mnozstvo_hu" &&
//                     params.row.mnozstvo_hu !== null) ||
//                   (params.field === "mnozstvo_at" &&
//                     params.row.mnozstvo_at !== null) ||
//                   (params.field === "mnozstvo_pl" &&
//                     params.row.mnozstvo_pl !== null)
//                 ) {
//                   return true;
//                 }
//                 return false;
//               }}
//             />
//           </StyledBox>
//         </div>
//         <ToastContainer autoClose={2} />
//       </div>
//     </div>
//   );
// }

// export default KartonyRozpisTable;

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../shared/Navbar";
import useWindowSize from "../../hooks/useWindow";
import * as urlHelp from "../../features/urlHelpers";
import {
  DataGridPremium,
  csCZ,
  GridColumns,
  useGridApiRef,
  GridRowsProp,
  GridToolbarExport,
  GridToolbarContainer,
  GridCellEditCommitParams,
  MuiBaseEvent,
  MuiEvent,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
//import { RootState } from "../../redux/store";
import Moment from "moment";
import { getLicense } from "../shared/license";
import { getRowStatus_isCorrect } from "../shared/search";
//import { logoutUser } from "../../redux/actions/authActions";
import { logout, log_or_not } from "../shared/authFunctions";
import axios from "axios";
import { loginData } from "../auth/redux/loginSlice";
import { errorHandleDialog } from "../shared/errorDialog";
import remove_accents from "remove-accents";
import styles from "./KartonyRozpisTable.module.sass";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

function KartonyRozpisTable() {
  //const [ztpData, setZtpData] = useState([] as GridRowsProp); //data do tabulky ziskane z backendu
  const [rows, setRows] = useState([] as GridRowsProp);
  const [rows_loaded, setRowsLoaded] = useState(false as boolean);
  const [searchValue, setSearchedValue] = useState("");
  const { width } = useWindowSize();
  const apiRef = useGridApiRef();
  const navigate = useHistory();
  const [date_vyr, setDate_vyr] = useState("");
  const [date_exp, setDate_exp] = useState("");
  const [day_vyr, setDay_vyr] = useState("");
  const [day_exp, setDay_exp] = useState("");
  const logindata = useSelector(loginData);
  const [date, setDate] = useState("");
  const dispatch = useDispatch();
  const [connection, setConnection] = useState<null | HubConnection>(null);

  //   // connection ID pre notifikacie
  const [notificationID, setNotificationID] = useState("");
  LicenseInfo.setLicenseKey(getLicense());

  const error = (stlpec: string) =>
    toast.error(
      "Chyba hodnoty nesmí být záporné ani desetinné číslo v " + stlpec + "!"
    );

  const success = (stlpec: string) =>
    toast.success("Položka byla upravena v " + stlpec + "!");

  async function getKartonyRozpisData() {
    await axios
      .get(
        urlHelp.kartonyRozpisUrl +
          "?datum_vyroba=" +
          urlHelp.get_datum_vyroba(logindata),
        urlHelp.get_header(logindata)
      )
      .then(function (response) {
        let i = 0;
        console.log("kartonyRozpisData", response.data.data);

        i = 0;
        let num_typr1 = "";
        const newData = response.data.data.map((obj: any) => {
          if (obj.typ === 1) {
            num_typr1 = "typ1-" + i;
          }
          console.log(num_typr1);
          return {
            ...obj,
            id: i++,
            num_typr1: num_typr1,
            typr: obj.typ,
          };
        });

        setRows(newData);
        setDate(urlHelp.get_datum_vyroba(logindata).slice(0, 10));
        setDate_vyr(response.data.datum_vyroba.slice(0, 10));
        setDate_exp(response.data.datum_expedice.slice(0, 10));
        setDay_vyr(response.data.den_vyroba);
        setDay_exp(response.data.den_expedice);
        setRowsLoaded(true);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(urlHelp.getNotificationUrl(logindata))
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          // ziskame notificationID ( kvoli update)
          connection.invoke("GetConnectionId").then(function (connectionID) {
            setNotificationID(connectionID);
          });

          connection.on("ReceiveMessage", (message) => {
            console.log("SignalR ReceiveMessage ", message);
            if (
              message.data === "kartony_rozpis" &&
              message.typ === "refresh" &&
              message.datum_vyroba === urlHelp.get_datum_vyroba(logindata)
            ) {
              setRowsLoaded(false);
              getKartonyRozpisData();
              //window.location.reload(false);
            }
          });
        })
        .catch((error) =>
          errorHandleDialog(error.response, navigate, dispatch)
        );
    }
  }, [connection]);

  useEffect(() => {
    log_or_not(logindata, navigate, dispatch);
    setRowsLoaded(false);
    getKartonyRozpisData();
  }, []);

  const styleTable = {
    height: window.innerHeight - 210.8,
    width: "100%",
    margin: 0,
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
    },
    "& .super-app-theme--1": {
      backgroundColor: "rgba(0,102,0,255)",
      color: "rgb(255, 255, 255)",
      "&:hover": {
        backgroundColor: "rgba(0,102,0,255)",
        color: "rgb(255, 255, 255)",
      },
    },
    "& .super-app-theme--2": {
      backgroundColor: "rgb(73, 69, 41)",
      color: "rgb(255, 255, 255)",
      "&:hover": {
        backgroundColor: "rgb(73, 69, 41)",
        color: "rgb(255, 255, 255)",
      },
    },
  };

  function isInt(n: any) {
    return n % 1 === 0;
  }

  function CustomToolbar() {
    return (
      <div>
        <GridToolbarContainer>
          <div className={styles.GridToolbar}>
            <div>
              <GridToolbarExport
                printOptions={{
                  hideFooter: true,
                }}
                csvOptions={{
                  delimiter: ";",
                  utf8WithBom: true,
                  fileName: "Výrobní plán - kartony",
                }}
              />
            </div>
            <div>
              Výroba: {Moment(date).format("DD.MM.YYYY")} Výrobní plán - kartony
            </div>
          </div>
        </GridToolbarContainer>
      </div>
    );
  }
  function CustomToolbarEmpty() {
    return <div></div>;
  }

  function filter(data: any) {
    let newData = data.filter(
      (row: any) =>
        String(
          remove_accents(row.nazov ? row.nazov.toUpperCase() : "")
        ).includes(remove_accents(searchValue.toUpperCase())) ||
        String(
          remove_accents(row.znacka_at ? row.znacka_at.toUpperCase() : "")
        ).includes(remove_accents(searchValue.toUpperCase())) ||
        String(
          remove_accents(row.znacka_cz ? row.znacka_cz.toUpperCase() : "")
        ).includes(remove_accents(searchValue.toUpperCase())) ||
        String(
          remove_accents(row.znacka_hu ? row.znacka_hu.toUpperCase() : "")
        ).includes(remove_accents(searchValue.toUpperCase())) ||
        String(
          remove_accents(row.znacka_pl ? row.znacka_pl.toUpperCase() : "")
        ).includes(remove_accents(searchValue.toUpperCase())) ||
        row.typr === 2 ||
        row.typr === 1
    );

    //*zabezpecuje aby sa nezobrazil riadok 1 ak pod nim niesu polozky
    const helpData = newData.filter((row: any) => {
      if (
        row.typr === 1 &&
        newData.filter(
          (row_: any) => row_.num_typr1 === row.num_typr1 && row_.typr === 0
        ).length === 0
      ) {
        return false;
      } else {
        return true;
      }
    });

    //*zabezpecuje aby sa nezobrazil riadok 2 ak v tabulke niesu polozky
    return helpData.filter((row: any) => {
      if (row.typr === 2 && helpData.length <= 1) {
        return false;
      } else {
        return true;
      }
    });
  }

  const columns: GridColumns = [
    {
      field: "nazov",
      headerName: "Kartonový výrobek",
      editable: false,
      width: 320,
      pinnable: false,
      sortable: false,
    },
    {
      field: "znacka_cz",
      headerName: "CZ",
      type: "number",
      editable: false,
      width: 130,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "mnozstvo_cz",
      headerName: "Kartony CZ",
      editable: true,
      width: 130,
      type: "number",
      pinnable: false,
      sortable: false,
      headerAlign: "center",
      renderCell: (params: any) => {
        let valueFormatted = Number(params.value as number);
        if (params.value === null) return;
        let sum = 0;

        if (params.row.typr === 2) {
          filter(rows).map((row: any) => {
            if (row.typr !== 2 && row.typr !== 1) {
              sum += row.mnozstvo_cz ? 0 : row.mnozstvo_cz;
            }
          });
          valueFormatted = sum;
        }

        const value = valueFormatted
          .toFixed(0)
          .replace(/\d(?=(\d{3})+\.)/g, "$& ");

        return <div className={styles.cell}>{`${value}`}</div>;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Kartony CZ");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "znacka_hu",
      headerName: "HU",
      editable: false,
      width: 130,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
    },

    {
      field: "mnozstvo_hu",
      headerName: "Kartony HU",
      width: 130,
      type: "number",
      editable: true,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
      renderCell: (params: any) => {
        let valueFormatted = Number(params.value as number);
        if (params.value === null) return;
        let sum = 0;

        if (params.row.typr === 2) {
          filter(rows).map((row: any) => {
            if (row.typr !== 2 && row.typr !== 1) {
              sum += row.mnozstvo_hu ? 0 : row.mnozstvo_hu;
            }
          });
          valueFormatted = sum;
        }

        const value = valueFormatted
          .toFixed(0)
          .replace(/\d(?=(\d{3})+\.)/g, "$& ");

        return <div className={styles.cell}>{`${value}`}</div>;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Kartony HU");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "znacka_at",
      headerName: "AT",
      width: 130,
      editable: false,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "mnozstvo_at",
      headerName: "Kartony AT",
      width: 130,
      type: "number",
      editable: true,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
      renderCell: (params: any) => {
        let valueFormatted = Number(params.value as number);
        if (params.value === null) return;
        let sum = 0;

        if (params.row.typr === 2) {
          filter(rows).map((row: any) => {
            if (row.typr !== 2 && row.typr !== 1) {
              sum += row.mnozstvo_at ? 0 : row.mnozstvo_at;
            }
          });
          valueFormatted = sum;
        }

        const value = valueFormatted
          .toFixed(0)
          .replace(/\d(?=(\d{3})+\.)/g, "$& ");

        return <div className={styles.cell}>{`${value}`}</div>;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Kartony AT");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "znacka_pl",
      headerName: "PL",
      width: 130,
      editable: false,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "mnozstvo_pl",
      headerName: "Kartony PL",
      width: 130,
      type: "number",
      editable: true,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
      renderCell: (params: any) => {
        let valueFormatted = Number(params.value as number);
        if (params.value === null) return;
        let sum = 0;

        if (params.row.typr === 2) {
          filter(rows).map((row: any) => {
            if (row.typr !== 2 && row.typr !== 1) {
              sum += row.mnozstvo_pl ? 0 : row.mnozstvo_pl;
            }
          });
          valueFormatted = sum;
        }

        const value = valueFormatted
          .toFixed(0)
          .replace(/\d(?=(\d{3})+\.)/g, "$& ");

        return <div className={styles.cell}>{`${value}`}</div>;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error("Kartony PL");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "mnozstvo_sum",
      headerName: "Kartony",
      width: 130,
      type: "number",
      editable: false,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
      renderCell: (params: any) => {
        let valueFormatted = Number(params.value as number);
        if (params.value === null) return;
        let sum = 0;

        if (params.row.typr === 2) {
          filter(rows).map((row: any) => {
            if (row.typr !== 2 && row.typr !== 1) {
              sum += row.mnozstvo_sum ? 0 : row.mnozstvo_sum;
            }
          });
          valueFormatted = sum;
        }

        const value = valueFormatted
          .toFixed(0)
          .replace(/\d(?=(\d{3})+\.)/g, "$& ");

        return <div className={styles.cell}>{`${value}`}</div>;
      },
    },
  ];
  return (
    <div>
      <Navbar />
      <div
        style={{
          height: window.innerHeight - 70.8,
        }}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.selectArea}>
              <div className={styles.vyrExpGrid}>
                <div className={styles.vyrExpGridRow}>
                  <div className={styles.vyrExpGridRowL}>Výroba</div>
                  <div className={styles.vyrExpGridRowR}>
                    {rows_loaded ? (
                      <div>{Moment(date_vyr).format("DD.MM.YYYY")}</div>
                    ) : (
                      <></>
                    )}
                    <div>{day_vyr}</div>
                  </div>
                </div>
                <div className={styles.vyrExpGridRow}>
                  <div className={styles.vyrExpGridRowL}>Expedice</div>
                  <div className={styles.vyrExpGridRowR}>
                    {rows_loaded ? (
                      <div>{Moment(date_exp).format("DD.MM.YYYY")}</div>
                    ) : (
                      <></>
                    )}
                    <div>{day_exp}</div>
                  </div>
                </div>
              </div>
            </div>

            <input
              onChange={(e) => {
                setSearchedValue(e.target.value);
              }}
              className={styles.input}
              placeholder="Hledej"
            ></input>
            <div className={styles.title}>Výrobní plán - kartony</div>
          </div>
          <div className={styles.grid}>
            {rows_loaded ? (
              <DataGridPremium
                disableColumnMenu={true}
                components={{
                  Toolbar:
                    rows.length === 1 ? CustomToolbarEmpty : CustomToolbar,
                }}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                apiRef={apiRef}
                sx={styleTable}
                hideFooter
                rows={filter(rows)}
                columns={columns}
                rowHeight={25}
                disableSelectionOnClick
                disableColumnReorder
                getRowClassName={(params: any) =>
                  `super-app-theme--${params.row.typ}`
                }
                onCellEditCommit={async function (
                  params: GridCellEditCommitParams,
                  event: MuiEvent<MuiBaseEvent>

                ) {
                  event.defaultMuiPrevented = true;
                  const row = rows.filter((row: any) => row.id === params.id);
                  if (params.value !== null) {
                    await axios
                      .post(
                        urlHelp.kartonyRozpisUrlUpdate,
                        {
                          datum_vyroba: urlHelp.get_datum_vyroba(logindata),
                          field_name: params.field,
                          field_value: params.value,
                          rowdata: row[0],
                          notificationID: notificationID,
                        },
                        urlHelp.get_header(logindata)
                      )
                      .then(function (response) {
                        let index = rows.indexOf(row[0]);
                        let data = [...rows];
                        let row_copy = { ...row[0] };
                        row_copy[params.field] = params.value;
                        data[index] = row_copy;
                        setRows(data);
                        success(params.field);

                        // apiRef.current.updateRows([
                        //   { id: params.id, [params.field]: params.value },
                        // ]);

                        // let i = 0;
                        // while (i < rows.length) {
                        //   if (rows[i].id === params.id) {
                        //     rows[i][params.field] = params.value;
                        //     break;
                        //   }
                        //   i++;
                        // }
                      })
                      .catch(function (error) {
                        errorHandleDialog(error.response, navigate, dispatch);
                      });
                  }
                }}
                isCellEditable={(params: any) => {
                  if (params.row.typr === 2) return false;
                  if (
                    (params.field === "mnozstvo_cz" &&
                      params.row.mnozstvo_cz !== null) ||
                    (params.field === "mnozstvo_hu" &&
                      params.row.mnozstvo_hu !== null) ||
                    (params.field === "mnozstvo_at" &&
                      params.row.mnozstvo_at !== null) ||
                    (params.field === "mnozstvo_pl" &&
                      params.row.mnozstvo_pl !== null)
                  ) {
                    return true;
                  }
                  return false;
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default KartonyRozpisTable;
