import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../shared/Navbar";
import useWindowSize from "../../hooks/useWindow";
import * as urlHelp from "../../features/urlHelpers";
import {
  DataGridPremium,
  csCZ,
  GridColumns,
  useGridApiRef,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import axios from "axios";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
//import { RootState } from "../../redux/store";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Input,
  dividerClasses,
} from "@mui/material";
//import { logoutUser } from "../../redux/actions/authActions";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { getLicense } from "../shared/license";
import { getRowStatus_isCorrect } from "../shared/search";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ErrorIcon from "@mui/icons-material/Error";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { logout, log_or_not } from "../shared/authFunctions";
import { loginData } from "../auth/redux/loginSlice";
import { errorHandleDialog } from "../shared/errorDialog";
import styles from "./UzivatelTable.module.sass";
import remove_accents from "remove-accents";

function UzivatelTable() {
  //const [uzivatelData, setUzivatelData] = useState([] as GridRowsProp); //data do tabulky ziskane z backendu
  const [rows, setRows] = useState([] as GridRowsProp);
  const [rows_loaded, setRowsLoaded] = useState(false as boolean);
  const dispatch = useDispatch();
  const [searchValue, setSearchedValue] = useState("" as string);
  const { width, height } = useWindowSize();
  const success_delete = () => toast.success("Použivatel byl odstranen!");
  const success_save = () => toast.success("Použivatel byl přidán!");
  const success_edit = () => toast.success("Použivatel byl upraven!");
  const apiRef = useGridApiRef();
  const navigate = useHistory();
  const logindata = useSelector(loginData);
  const [edit_dialog_header, setEdit_dialog_header] = useState("");

  const [password_valid, setPassword_valid] = useState(true);

  const [open, setOpen] = React.useState(false);
  const [error_dialog, setError_dialog] = React.useState("");

  const [userData, setUserData] = useState({
    id: 0,
    username: "",
    domainuser: false,
    password: "",
    new_password: "",
    email: "",
    skupina: "",
    admin: false,
  });

  const [open_edit, setOpen_edit] = React.useState(false);
  const [open_delete, setOpen_delete] = React.useState(false);
  // connection ID pre notifikacie
  LicenseInfo.setLicenseKey(getLicense());

  useEffect(() => {
    setRowsLoaded(false);
    getUzivatelData();
  }, []);

  const styleTable = {
    height: window.innerHeight - 210.8,
    width: "100%",
    margin: 0,
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
    },
    "& .super-app-theme--header": {
      backgroundColor: "rgba(0,102,0,255)",
      color: "rgb(255, 255, 255)",
    },
    "& .MuiDataGrid-colCell:scroll": {
      outline: "none",
    },
  };

  async function getUzivatelData() {
    log_or_not(logindata, navigate, dispatch);
    //console.log(pecivoUrl + "?datum_vyroba=" + ldata.userInfo.ConfigData.datum_vyroba.slice(0, 10));
    await axios
      .get(
        urlHelp.usersUrl +
          "?datum_vyroba=" +
          urlHelp.get_datum_vyroba(logindata),
        urlHelp.get_header(logindata)
      )
      .then(function (response) {
        setRows(response.data);
        setRowsLoaded(true);
      })
      .catch(function (error) {
        console.log(error);
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function handleDeleteClick(row: any) {
    setOpen_delete(true);

    if (row !== null) {
      setUserData({
        id: row.id,
        username: row.username,
        domainuser: row.domainuser,
        password: "",
        new_password: "",
        email: row.email,
        skupina: row.skupina,
        admin: row.admin,
      });
    }
  }

  async function delete_user() {
    const headers = {
      Authorization: "Bearer " + logindata.Token,
    };
    const data = {
      id: userData.id,
    };
    axios
      .delete(urlHelp.usersUrl, { headers, data })
      .then(function (response) {
        success_delete();
        getUzivatelData();
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
    setOpen_delete(false);
  }

  const handleEditClick = (row: any) => {
    setPassword_valid(true);
    if (row !== null) {
      setUserData({
        id: row.id,
        username: row.username,
        domainuser: row.domainuser,
        password: "",
        new_password: "",
        email: row.email,
        skupina: row.skupina,
        admin: row.admin,
      });
    }
    setOpen_edit(true);
  };

  function handleClose_edit() {
    setOpen_edit(false);
  }

  function handleCheckBox_edit(checkBox: string) {
    if (checkBox === "admin") {
      setUserData({
        id: userData.id,
        username: userData.username,
        domainuser: userData.domainuser,
        password: userData.password,
        new_password: userData.new_password,
        email: userData.email,
        skupina: userData.skupina,
        admin: userData.admin === true ? false : true,
      });
    }
    if (checkBox === "domain") {
      setUserData({
        id: userData.id,
        username: userData.username,
        domainuser: userData.domainuser === true ? false : true,
        password: userData.password,
        new_password: userData.new_password,
        email: userData.email,
        skupina: userData.skupina,
        admin: userData.admin,
      });
    }
  }
  function password_validation(password1: string, password2: string) {
    if (password1 === password2) {
      setPassword_valid(true);
    } else {
      setPassword_valid(false);
    }
    if (password2 === "" && password1 === "") {
      setPassword_valid(true);
    }
  }

  async function handleSaveClick() {
    await axios
      .post(
        urlHelp.usersUrl,
        {
          id: userData.id,
          username: userData.username,
          new_password:
            userData.domainuser === false && userData.password !== ""
              ? userData.password
              : null,
          domainuser: userData.domainuser,
          admin: userData.admin,
          email: userData.email,
          skupina: userData.skupina,
        },
        urlHelp.get_header(logindata)
      )
      .then(function (response) {
        if (userData.id === 0) {
          success_save();
        } else {
          success_edit();
        }
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
    getUzivatelData();
    setOpen_edit(false);
  }

  function handleClose_delete() {
    setOpen_delete(false);
  }

  function EditToolbar(props: any) {
    const handleClick = () => {
      setEdit_dialog_header("Přidat uživatele");
      setUserData({
        id: 0,
        username: "",
        domainuser: false,
        password: "",
        new_password: "",
        email: "",
        skupina: "",
        admin: false,
      });

      setOpen_edit(true);
    };

    return (
      <GridToolbarContainer>
        <div className={styles.GridToolbar}>
          <Button
            color="primary"
            startIcon={<PersonAddAltIcon />}
            onClick={handleClick}
          >
            Přidat použivatele
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }
  function CustomToolbarEmpty() {
    return <div></div>;
  }

  const columns: GridColumns = [
    {
      field: "id",
      headerName: "Id",
      type: "number",
      editable: true,
      width: 60,
      pinnable: false,
      headerAlign: "center",
    },
    {
      field: "username",
      headerName: "Přihlašovací jméno",
      editable: true,
      width: 140,
      pinnable: false,
      headerAlign: "center",
    },
    {
      field: "domainuser",
      headerName: "Doménový uživatel",
      type: "boolean",
      editable: true,
      width: 150,
      pinnable: false,
      headerAlign: "center",
    },
    {
      field: "password",
      headerName: "Heslo",
      headerAlign: "center",
      width: 100,
      //editable: true,
      type: "actions",
      pinnable: false,
      getActions: (params: any) => {
        return [<div>*******</div>];
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      width: 150,
      editable: true,
      pinnable: false,
    },

    {
      field: "skupina",
      headerName: "Skupina",
      headerAlign: "center",
      width: 100,
      editable: true,
      pinnable: false,
    },
    {
      field: "admin",
      headerName: "Admin",
      headerAlign: "center",
      width: 100,
      type: "boolean",
      editable: true,
      pinnable: false,
    },
    {
      field: "Edituj",
      headerAlign: "center",
      width: 80,
      pinnable: true,
      renderCell: (params: any) => (
        <>
          <Button
            onClick={() => {
              handleEditClick(params.row);
              setEdit_dialog_header("Úprava uživatele");
            }}
          >
            <ModeEditOutlineIcon />
          </Button>
        </>
      ),
    },
    {
      field: "Smaž",
      headerAlign: "center",
      width: 80,
      pinnable: true,
      renderCell: (params: any) => (
        <>
          <Button
            color="error"
            onClick={() => {
              handleDeleteClick(params.row);
            }}
          >
            <DeleteIcon />
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <Navbar />
      <div
        style={{
          height: window.innerHeight - 70.8,
        }}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <input
              onChange={(e) => {
                setSearchedValue(e.target.value);
              }}
              className={styles.input}
              placeholder="Hledej"
            ></input>
            <div className={styles.title}>Seznam uživatelů</div>
          </div>
          <Dialog
            open={open_edit}
            onClose={handleClose_edit}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <AccountCircleIcon /> {edit_dialog_header}
              {userData.username}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <MuiGrid container spacing={1}>
                  <MuiGrid item xs={6}>
                    <TextField
                      margin="normal"
                      name="firstName"
                      label="Přihlašovací jméno"
                      value={userData.username}
                      onChange={(e: any) => {
                        setUserData({
                          id: userData.id,
                          username: e.target.value,
                          domainuser: userData.domainuser,
                          password: userData.password,
                          new_password: userData.new_password,
                          email: userData.email,
                          skupina: userData.skupina,
                          admin: userData.admin,
                        });
                      }}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <TextField
                      margin="normal"
                      name="Email"
                      label="Email"
                      value={userData.email}
                      onChange={(e: any) => {
                        setUserData({
                          id: userData.id,
                          username: userData.username,
                          domainuser: userData.domainuser,
                          password: userData.password,
                          new_password: userData.new_password,
                          email: e.target.value,
                          skupina: userData.skupina,
                          admin: userData.admin,
                        });
                      }}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            handleCheckBox_edit("domain");
                          }}
                          checked={userData.domainuser}
                        />
                      }
                      label="Doménový uživatel"
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            handleCheckBox_edit("admin");
                          }}
                          checked={userData.admin}
                        />
                      }
                      label="Admin"
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <TextField
                      margin="normal"
                      name="prefix"
                      label="Heslo"
                      type="password"
                      disabled={userData.domainuser}
                      value={userData.password}
                      onChange={(e: any) => {
                        setUserData({
                          id: userData.id,
                          username: userData.username,
                          domainuser: userData.domainuser,
                          password: e.target.value,
                          new_password: userData.new_password,
                          email: userData.email,
                          skupina: userData.skupina,
                          admin: userData.admin,
                        });
                        password_validation(
                          e.target.value,
                          userData.new_password
                        );
                      }}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <TextField
                      margin="normal"
                      name="lastName"
                      label="Potvrdzovací heslo"
                      type="password"
                      disabled={userData.domainuser}
                      value={userData.new_password}
                      onChange={(e: any) => {
                        setUserData({
                          id: userData.id,
                          username: userData.username,
                          domainuser: userData.domainuser,
                          password: userData.password,
                          new_password: e.target.value,
                          email: userData.email,
                          skupina: userData.skupina,
                          admin: userData.admin,
                        });
                        password_validation(userData.password, e.target.value);
                      }}
                    />
                  </MuiGrid>
                  <MuiGrid item xs={6}>
                    <TextField
                      margin="normal"
                      name="phone"
                      label="Skupina"
                      value={userData.skupina}
                      onChange={(e: any) => {
                        setUserData({
                          id: userData.id,
                          username: userData.username,
                          domainuser: userData.domainuser,
                          password: userData.password,
                          new_password: userData.new_password,
                          email: userData.email,
                          skupina: e.target.value,
                          admin: userData.admin,
                        });
                      }}
                    />
                  </MuiGrid>

                  <MuiGrid item xs={6}>
                    <div hidden={password_valid} style={{ color: "red" }}>
                      <ErrorIcon /> Hesla sa neshodují
                    </div>
                  </MuiGrid>
                </MuiGrid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose_edit();
                }}
              >
                <CancelIcon /> Zrušiť
              </Button>
              <Button
                disabled={!password_valid}
                onClick={() => {
                  handleSaveClick();
                }}
              >
                <SaveIcon /> Uložiť
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open_delete}
            onClose={handleClose_delete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Chcete smazat uživatele "} {userData.username} {" ?"}
            </DialogTitle>

            <DialogActions>
              <Button onClick={delete_user} autoFocus>
                Ano
              </Button>
              <Button onClick={handleClose_delete} autoFocus>
                Ne
              </Button>
            </DialogActions>
          </Dialog>
          <div className={styles.grid}>
            {rows_loaded ? (
              <DataGridPremium
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                sx={styleTable}
                apiRef={apiRef}
                components={{
                  Toolbar: rows.length === 0 ? CustomToolbarEmpty : EditToolbar,
                }}
                componentsProps={{
                  toolbar: { apiRef },
                }}
                hideFooter
                rows={rows.filter(
                  (row) =>
                    String(remove_accents(row.username.toUpperCase())).includes(
                      remove_accents(searchValue.toUpperCase())
                    )
                )}
                columns={columns}
                rowHeight={40}
                disableColumnMenu
                disableMultipleColumnsSorting
                disableColumnReorder
                initialState={{ pinnedColumns: { right: ["Edituj", "Smaž"] } }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2} />
    </div>
  );
}

export default UzivatelTable;

// import React, { useState, useEffect } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import Navbar from "../shared/Navbar";
// import useWindowSize from "../../hooks/useWindow";
// import * as urlHelp from "../../features/urlHelpers";
// import {
//   DataGridPremium,
//   csCZ,
//   GridColumns,
//   GridCallbackDetails,
//   MuiBaseEvent,
//   MuiEvent,
//   useGridApiRef,
//   GridRowsProp,
//   GridValueFormatterParams,
//   GridCellEditCommitParams,
// } from "@mui/x-data-grid-premium";
// import axios from "axios";
// import { LicenseInfo } from "@mui/x-data-grid-premium";
// import { useHistory, useParams } from "react-router-dom";
// import Stack from "@mui/material/Stack";
// import { useDispatch, useSelector } from "react-redux";
// //import { RootState } from "../../redux/store";
// import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
// import { logout, log_or_not } from "../shared/authFunctions";
// import styles from "./PrahaTable.module.sass";

// //import { logoutUser } from "../../redux/actions/authActions";
// import { getLicense } from "../shared/license";
// import { getRowStatus_isCorrect } from "../shared/search";
// import { loginData } from "../auth/redux/loginSlice";
// import { errorHandleDialog } from "../shared/errorDialog";
// import remove_accents from "remove-accents";

// function PrahaTable() {
//   const [prahaData, setPrahaData] = useState([] as GridRowsProp); //data do tabulky ziskane z backendu
//   const [rows, setRows] = useState([] as GridRowsProp);
//   const [rows_loaded, setRowsLoaded] = useState(false as boolean);

//   const [searchValue, setSearchedValue] = useState("" as string);
//   const success = (znacka: string) =>
//     toast.success("Stav značky" + znacka + "byl upraven!");
//   const error = () => toast.error("Chybna hodnota stavu skladu.");
//   const apiRef = useGridApiRef();
//   const navigate = useHistory();
//   const dispatch = useDispatch();
//   const logindata = useSelector(loginData);
//   const [connection, setConnection] = useState<null | HubConnection>(null);
//   const [open, setOpen] = React.useState(false);
//   const [error_dialog, setError_dialog] = React.useState("");
//   // connection ID pre notifikacie
//   const [notificationID, setNotificationID] = useState("");
//   LicenseInfo.setLicenseKey(getLicense());

//   useEffect(() => {
//     setRowsLoaded(false);
//     getPrahaData();
//   }, []);

//   useEffect(() => {
//     const connect = new HubConnectionBuilder()
//       .withUrl(urlHelp.getNotificationUrl(logindata))
//       .withAutomaticReconnect()
//       .build();

//     setConnection(connect);
//   }, []);

//   useEffect(() => {
//     if (connection) {
//       connection
//         .start()
//         .then(() => {
//           // ziskame notificationID ( kvoli update)
//           connection.invoke("GetConnectionId").then(function (connectionID) {
//             setNotificationID(connectionID);
//           });

//           connection.on("ReceiveMessage", (message) => {
//             //console.log("SignalR ReceiveMessage ", message)
//             if (
//               message.data === "skladpraha" &&
//               message.typ === "refresh" &&
//               message.datum_vyroba === urlHelp.get_datum_vyroba(logindata)
//             ) {
//               setRowsLoaded(false);
//               getPrahaData();
//               //window.location.reload(false);
//             }
//           });
//         })
//         .catch((error) =>
//           errorHandleDialog(error.response, navigate, dispatch)
//         );
//     }
//   }, [connection]);

//   const styleTable = {
//     height: window.innerHeight - 210.8,
//     width: "100%",
//     margin: 0,
//     "& .MuiDataGrid-cell--editable": {
//       backgroundColor: "rgb(195, 247, 195)",
//       "&:hover": {
//         backgroundColor: "rgb(240,250,190)",
//       },
//     },
//     "& .super-app-theme--header": {
//       backgroundColor: "rgba(0,102,0,255)",
//       color: "rgb(255, 255, 255)",
//     },
//     "& .MuiDataGrid-colCell:scroll": {
//       outline: "none",
//     },
//   };

//   async function getPrahaData() {
//     log_or_not(logindata, navigate, dispatch);
//     await axios
//       .get(
//         urlHelp.apiUrl + "?datum_vyroba=" + urlHelp.get_datum_vyroba(logindata),
//         urlHelp.get_header(logindata)
//       )
//       .then(function (response) {
//         const newData = response.data.data.map((obj: any) => ({
//           ...obj,
//           id: obj.znacka,
//         }));
//         setRows(newData);
//         console.log("prahadata", newData);
//         //setPrahaData(newData);
//         setRowsLoaded(true);
//         //search_help(newData, searchValue);
//       })
//       .catch(function (error) {
//         errorHandleDialog(error.response, navigate, dispatch);
//       });
//   }

//   function isInt(n: any) {
//     return n % 1 === 0;
//   }

//   const columns: GridColumns = [
//     {
//       field: "znacka",
//       headerName: "Značka",
//       editable: false,
//       width: 150,
//       pinnable: false,
//       headerAlign: "center",
//     },
//     {
//       field: "nazov",
//       headerName: " Název výrobku",
//       editable: false,
//       width: 260,
//       pinnable: false,
//       headerAlign: "center",
//     },
//     {
//       field: "stav",
//       headerName: "Stav",
//       type: "number",
//       headerAlign: "center",
//       editable: true,
//       width: 150,
//       pinnable: false,
//       valueFormatter: (params: GridValueFormatterParams) => {
//         if (params.value === null || params.value === 0) {
//           return "";
//         }
//         const valueFormatted = Number(params.value as number);

//         const value = valueFormatted
//           .toFixed(0)
//           .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

//         return `${value}`;
//       },
//       preProcessEditCellProps: (params: any) => {
//         const hasError = params.props.value < 0 || !isInt(params.props.value);
//         if (hasError) {
//           error();
//         }
//         return { ...params.props, error: hasError };
//       },
//     },
//     {
//       field: "povodnyStav",
//       headerName: " Původní stav",
//       type: "number",
//       headerAlign: "center",
//       width: 150,
//       editable: false,
//       pinnable: false,
//       valueFormatter: (params: GridValueFormatterParams) => {
//         if (params.value === null || params.value === 0) {
//           return "";
//         }
//         const valueFormatted = Number(params.value as number);

//         const value = valueFormatted
//           .toFixed(0)
//           .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

//         return `${value}`;
//       },
//     },
//     {
//       field: "casPovodnejZmeny",
//       headerName: "Čas poslední změny",
//       type: "number",
//       headerAlign: "center",
//       width: 150,
//       editable: false,
//       pinnable: false,
//     },
//   ];

//   return (
//     <div>
//       <Navbar />

//       <div
//         style={{
//           height: window.innerHeight - 70.8,
//         }}
//       >
//         <div className={styles.content}>
//           <div className={styles.header}>
//             <input
//               onChange={(e) => {
//                 setSearchedValue(e.target.value);
//               }}
//               className={styles.input}
//               placeholder="Hledej"
//             ></input>
//             <div className={styles.title}>Stav skladu Praha</div>
//           </div>
//           <div className={styles.grid}>
//             {rows_loaded ? (
//               <DataGridPremium
//                 localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
//                 sx={styleTable}
//                 apiRef={apiRef}
//                 hideFooter
//                 rows={rows.filter(
//                   (row) =>
//                     String(remove_accents(row.znacka.toUpperCase())).includes(
//                       remove_accents(searchValue.toUpperCase())
//                     ) ||
//                     String(remove_accents(row.nazov.toUpperCase())).includes(
//                       remove_accents(searchValue.toUpperCase())
//                     )
//                 )}
//                 columns={columns}
//                 rowHeight={25}
//                 disableColumnMenu
//                 disableColumnReorder
//                 onCellEditCommit={async function (
//                   params: GridCellEditCommitParams,
//                   event: MuiEvent<MuiBaseEvent>
//                 ) {

//                   event.defaultMuiPrevented = true;

//                   if (params.value !== null) {
//                     await axios
//                       .post(
//                         urlHelp.apiUpdateUrl,
//                         {
//                           datum_vyroba: urlHelp.get_datum_vyroba(logindata),
//                           znacka: params.id,
//                           stav: params.value,
//                           notificationID: notificationID,
//                         },
//                         urlHelp.get_header(logindata)
//                       )
//                       .then(function (response) {
//                         console.log(response);
//                         const row = rows.filter(
//                           (row: any) => row.id === params.id
//                         );
//                         let index = rows.indexOf(row[0]);
//                         let data = [...rows];
//                         let row_copy = { ...row[0] };
//                         row_copy.stav = params.value;
//                         row_copy.casPovodnejZmeny =
//                           response.data[0].casPovodnejZmeny;
//                         row_copy.povodnyStav = response.data[0].povodnyStav;
//                         data[index] = row_copy;
//                         setRows(data);

//                         success(row[0].id);
//                       })
//                       .catch(function (error) {
//                         errorHandleDialog(error.response, navigate, dispatch);
//                       });
//                   }
//                 }}
//                 initialState={{ pinnedColumns: { left: ["znacka"] } }}
//               />
//             ) : (
//               <></>
//             )}
//           </div>
//         </div>
//       </div>
//       <ToastContainer autoClose={2} />
//     </div>
//   );
// }

// export default PrahaTable;
