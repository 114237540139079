import { useDispatch } from "react-redux";
//import { logoutUser } from "../../redux/actions/authActions";
import { login } from "../auth/redux/loginSlice";

export function log_or_not(ldata: any, navigate: any, dispatch: any) {
  if (ldata.UserData === undefined) {
    logout(navigate, dispatch);
  }
  console.log(ldata);
  let expiration_time = new Date(ldata.TokenExpirationTime * 1000);
  expiration_time = new Date(expiration_time.toUTCString());
  const current_time = new Date(new Date().toUTCString());

  console.log(expiration_time);
  console.log(current_time);
  if (current_time > expiration_time) {
    logout(navigate, dispatch);
  }
}

export function logout(navigate: any, dispatch: any) {
  navigate.push("/");
  dispatch(login({ data: {} } as any));
  //window.location.reload(false);
}
