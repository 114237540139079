const serverUrl = process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_PROD_URL}` : `${process.env.REACT_APP_DEV_URL}`;

export const apiUrl = serverUrl + `api/vyroba/SkladPraha`;
export const apiUpdateUrl = serverUrl + `api/vyroba/SkladPraha/update`;
export const zpeUrl = serverUrl + `api/vyroba/zpe`;
export const authUrl = serverUrl + `api/vyroba/login`;
export const kartonyRozpisUrl = serverUrl + `api/vyroba/kartonyrozpis`;
export const kartonyRozpisUrlUpdate = serverUrl + `api/vyroba/kartonyrozpis/update`;
export const pecivoUrl = serverUrl + `api/vyroba/pecivo`;
export const pecivoUrlUpdate = serverUrl + `api/vyroba/pecivo/update`;
export const ztpUrl = serverUrl + `api/vyroba/ztp`;
export const zvyUrl = serverUrl + `api/vyroba/zvy`;
export const vyrrozpisUrl = serverUrl + `api/vyroba/vyrrozpis`;
export const vyrrozpisUrlUpdate = serverUrl + `api/vyroba/vyrrozpis/update`;
export const aktualizaciaVyrRozpisUrl = serverUrl + `api/vyroba/aktualizaciaVyrRozpis`;


export const userRefreshUrl = serverUrl + `api/vyroba/refreshtoken`;
export const notificationUrl = serverUrl + `hubs/notification`;
export const usersUrl = serverUrl + `api/admin/users`;
export const urlVrozpisdef = serverUrl + `api/admin/vrozpisdef`;
export const urlVrozpisdefUpdate = serverUrl + `api/admin/vrozpisdef/update`;
export const urlKartonysdef = serverUrl + `api/admin/kartonydef`;
export const urlKartonysdefUpdate = serverUrl + `api/admin/kartonydef/update`;
// 
// vratime notifikacnu url spolu s username ( ak sme prihlaseny)
//
export function getNotificationUrl(logindata: any) {

    return notificationUrl + (logindata ? "?username=" + logindata.UserData.username : "");
}

export function get_datum_vyroba(logindata: any) {

    return logindata.ConfigData.datum_vyroba as string;
}

export function get_header(logindata: any) {

    return { headers: { Authorization: 'Bearer ' + logindata.Token } };
}

export function get_token(logindata: any) {

    return logindata.Token;
}