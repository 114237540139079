import React, { useEffect, useState } from "react";
import { Input, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { authUrl } from "../../features/urlHelpers";
import axios from "axios";
import { login, loginData } from "./redux/loginSlice";
import { use100vh } from "react-div-100vh";
import styles from "./login.module.sass";
import swal from "sweetalert";
import { TailSpin } from "react-loader-spinner";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [log, setLog] = useState(true);
  const [logText, setLogText] = useState("");
  const [isDevelop, setIsDevelop] = useState(
    process.env.NODE_ENV === "production" ? false : true
  );
  const dispatch = useDispatch();
  const heightWindow = use100vh();
  const logindata = useSelector(loginData);

  const navigate = useHistory();

  useEffect(() => {
    console.log(caches);
    console.log(caches.delete);
    caches.keys().then((cacheNames) => {
      console.log(cacheNames);
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
    console.log("cache is deleted");
  }, []);

  async function submitHandler(username: string, password: string) {
    setLoading(true);
    await axios
      .post(authUrl, {
        username: username,
        password: password,
      })
      .then(function (response) {
        setLoading(true);
        dispatch(login({ data: response.data } as any));
        setLoading(false);
        navigate.push("/menu");
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        swal("Chyba!", error.response.data, "error");
        if ("ERR_BAD_REQUEST" === error.code) {
          setLogText("Zadejte přihlašovací údaje k vašemu účtu.");
        }
        if ("ERR_NETWORK" === error.code) {
          setLogText(
            "Server je momentálně nedostupný. \nOpakujte přihlášení později."
          );
        }

        setLog(false);
      });
  }

  function butonyDev() {
    return (
      <div className={styles.butonynaprihlasenie} hidden={!isDevelop}>
        <Button
          color="success"
          style={{ margin: "1%" }}
          variant="contained"
          onClick={async () => {
            submitHandler("test", "test");
          }}
          className={styles.button_sign_in_help}
        >
          Admin
        </Button>

        <Button
          color="success"
          style={{ margin: "1%" }}
          variant="contained"
          onClick={async () => {
            submitHandler("testadmin", "testadmin");
          }}
          className={styles.button_sign_in_help}
        >
          Admintest
        </Button>
      </div>
    );
  }

  return (
    <div
      className={styles.content}
      style={{ minHeight: heightWindow as number }}
    >
      <>
        {loading ? (
          <div>
            <div className={styles.loader}>
              <TailSpin
                height="24"
                width="24"
                color="#22a638"
                ariaLabel="loading"
              />
            </div>
            <div>Probíhá přihlašování</div>
          </div>
        ) : (
          <div>
            <h1 className="flex justify-center align-top mt-8 text-3xl">
              Vyroba Web
            </h1>
            <div className={styles.title}>
              v.{process.env.REACT_APP_VERSION}
            </div>

            <div className={styles.body}>
              <div>
                <Input
                  className={styles.input}
                  value={username}
                  onChange={(e: any) => setUsername(e.target.value)}
                  placeholder="test"
                  onKeyPress={async (e) => {
                    if (e.key === "Enter") {
                      submitHandler(username, password);
                    }
                  }}
                />
              </div>
              <div>
                <Input
                  className={styles.input}
                  type="password"
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                  placeholder="test"
                  onKeyPress={async (e) => {
                    if (e.key === "Enter") {
                      submitHandler(username, password);
                    }
                  }}
                />
              </div>
            </div>
            <br />
            <div
              hidden={log}
              style={{ whiteSpace: "pre-wrap" }}
              className={styles.badLogin}
            >
              {logText}
            </div>
            {/* <BaseButton type="submit" ={submitHandler} name="Přihlásit" /> */}
            <div className={styles.button_area}>
              <Button
                style={{ margin: "1%" }}
                variant="contained"
                onClick={() => {
                  submitHandler(username, password);
                }}
                color="success"
              >
                Přihlásit
              </Button>
            </div>
            {butonyDev()}
          </div>
        )}
      </>

      <ToastContainer />
    </div>
  );
}

export default LoginForm;
