import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { RootState } from "../../redux/store";
import "../../pages/Error404.css";
import Navbar from "../shared/Navbar";
import { logout, log_or_not } from "../shared/authFunctions";
import { loginData } from "../auth/redux/loginSlice";
import styles from "./MenuInfo.module.sass";

function MenuInfo() {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const logindata = useSelector(loginData);

  useEffect(() => {
    //dispatch(getAllZtpData(logindata.userInfo.Token, logindata.userInfo.ConfigData.datum_vyroba.slice(0, 10), "get"))
    log_or_not(logindata, navigate, dispatch);
  }, []);

  const buttons = [
    { title: "Vyroba rozpis", path: "/vyrobaRozpis" },
    { title: "Kartony rozpis", path: "/kartonyRozpis" },
    { title: "Sklad praha", path: "/skladPraha" },
    { title: "Zvy", path: "/zvy" },
    { title: "Zpe", path: "/zpe" },
    { title: "Ztp", path: "/ztp" },
    { title: "Pečivo", path: "/pecivo" },
  ];

  function redirect(path: string) {
    navigate.push(path);
  }

  function getGrid() {
    if (window.innerWidth >= 825) {
      return "repeat(3, 1fr)";
    } else {
      return "repeat(2, 1fr)";
    }
  }

  return (
    <div>
      <Navbar page={"menu"}></Navbar>
      <div>
        <h1 className="flex justify-center align-top mt-8 text-3xl">
          Výrobní tabulky
        </h1>
      </div>
      <div
        className={styles.overlay}
        style={{
          display: "grid",
          gridTemplateColumns: getGrid(),
        }}
      >
        {buttons.map((button_, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                redirect(button_.path);
              }}
              className={styles.button}
            >
              {button_.title}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MenuInfo;
