import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../shared/Navbar";
import useWindowSize from "../../hooks/useWindow";
import * as urlHelp from "../../features/urlHelpers";
import {
  DataGridPremium,
  csCZ,
  GridColumns,
  GridCallbackDetails,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
  GridRowsProp,
  GridValueFormatterParams,
  GridCellEditCommitParams,
} from "@mui/x-data-grid-premium";
import axios from "axios";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { useHistory, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
//import { RootState } from "../../redux/store";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { logout, log_or_not } from "../shared/authFunctions";
import styles from "./PrahaTable.module.sass";

//import { logoutUser } from "../../redux/actions/authActions";
import { getLicense } from "../shared/license";
import { getRowStatus_isCorrect } from "../shared/search";
import { loginData } from "../auth/redux/loginSlice";
import { errorHandleDialog } from "../shared/errorDialog";
import remove_accents from "remove-accents";

function PrahaTable() {
  const [prahaData, setPrahaData] = useState([] as GridRowsProp); //data do tabulky ziskane z backendu
  const [rows, setRows] = useState([] as GridRowsProp);
  const [rows_loaded, setRowsLoaded] = useState(false as boolean);

  const [searchValue, setSearchedValue] = useState("" as string);
  const success = (znacka: string) =>
    toast.success("Stav značky" + znacka + "byl upraven!");
  const error = () => toast.error("Chybna hodnota stavu skladu.");
  const apiRef = useGridApiRef();
  const navigate = useHistory();
  const dispatch = useDispatch();
  const logindata = useSelector(loginData);
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [open, setOpen] = React.useState(false);
  const [error_dialog, setError_dialog] = React.useState("");
  // connection ID pre notifikacie
  const [notificationID, setNotificationID] = useState("");
  LicenseInfo.setLicenseKey(getLicense());

  useEffect(() => {
    setRowsLoaded(false);
    getPrahaData();
  }, []);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(urlHelp.getNotificationUrl(logindata))
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          // ziskame notificationID ( kvoli update)
          connection.invoke("GetConnectionId").then(function (connectionID) {
            setNotificationID(connectionID);
          });

          connection.on("ReceiveMessage", (message) => {
            //console.log("SignalR ReceiveMessage ", message)
            if (
              message.data === "skladpraha" &&
              message.typ === "refresh" &&
              message.datum_vyroba === urlHelp.get_datum_vyroba(logindata)
            ) {
              setRowsLoaded(false);
              getPrahaData();
              //window.location.reload(false);
            }
          });
        })
        .catch((error) =>
          errorHandleDialog(error.response, navigate, dispatch)
        );
    }
  }, [connection]);

  const styleTable = {
    height: window.innerHeight - 210.8,
    width: "100%",
    margin: 0,
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(195, 247, 195)",
      "&:hover": {
        backgroundColor: "rgb(240,250,190)",
      },
    },
    "& .super-app-theme--header": {
      backgroundColor: "rgba(0,102,0,255)",
      color: "rgb(255, 255, 255)",
    },
    "& .MuiDataGrid-colCell:scroll": {
      outline: "none",
    },
  };

  async function getPrahaData() {
    log_or_not(logindata, navigate, dispatch);
    await axios
      .get(
        urlHelp.apiUrl + "?datum_vyroba=" + urlHelp.get_datum_vyroba(logindata),
        urlHelp.get_header(logindata)
      )
      .then(function (response) {
        const newData = response.data.data.map((obj: any) => ({
          ...obj,
          id: obj.znacka,
        }));
        setRows(newData);
        console.log("prahadata", newData);
        //setPrahaData(newData);
        setRowsLoaded(true);
        //search_help(newData, searchValue);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  function isInt(n: any) {
    return n % 1 === 0;
  }

  const columns: GridColumns = [
    {
      field: "znacka",
      headerName: "Značka",
      editable: false,
      width: 150,
      pinnable: false,
      headerAlign: "center",
    },
    {
      field: "nazov",
      headerName: " Název výrobku",
      editable: false,
      width: 260,
      pinnable: false,
      headerAlign: "center",
    },
    {
      field: "stav",
      headerName: "Stav",
      type: "number",
      headerAlign: "center",
      editable: true,
      width: 150,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value as number);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
      preProcessEditCellProps: (params: any) => {
        const hasError = params.props.value < 0 || !isInt(params.props.value);
        if (hasError) {
          error();
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "povodnyStav",
      headerName: " Původní stav",
      type: "number",
      headerAlign: "center",
      width: 150,
      editable: false,
      pinnable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value === null || params.value === 0) {
          return "";
        }
        const valueFormatted = Number(params.value as number);

        const value = valueFormatted
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+\b)/g, "$1 ");

        return `${value}`;
      },
    },
    {
      field: "casPovodnejZmeny",
      headerName: "Čas poslední změny",
      type: "number",
      headerAlign: "center",
      width: 150,
      editable: false,
      pinnable: false,
    },
  ];

  return (
    <div>
      <Navbar />

      <div
        style={{
          height: window.innerHeight - 70.8,
        }}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <input
              onChange={(e) => {
                setSearchedValue(e.target.value);
              }}
              className={styles.input}
              placeholder="Hledej"
            ></input>
            <div className={styles.title}>Stav skladu Praha</div>
          </div>
          <div className={styles.grid}>
            {rows_loaded ? (
              <DataGridPremium
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                sx={styleTable}
                apiRef={apiRef}
                hideFooter
                rows={rows.filter(
                  (row) =>
                    String(remove_accents(row.znacka.toUpperCase())).includes(
                      remove_accents(searchValue.toUpperCase())
                    ) ||
                    String(remove_accents(row.nazov.toUpperCase())).includes(
                      remove_accents(searchValue.toUpperCase())
                    )
                )}
                columns={columns}
                rowHeight={25}
                disableColumnMenu
                disableColumnReorder
                onCellEditCommit={async function (
                  params: GridCellEditCommitParams,
                  event: MuiEvent<MuiBaseEvent>
                ) {
                  
                  event.defaultMuiPrevented = true;

                  if (params.value !== null) {
                    await axios
                      .post(
                        urlHelp.apiUpdateUrl,
                        {
                          datum_vyroba: urlHelp.get_datum_vyroba(logindata),
                          znacka: params.id,
                          stav: params.value,
                          notificationID: notificationID,
                        },
                        urlHelp.get_header(logindata)
                      )
                      .then(function (response) {
                        console.log(response);
                        const row = rows.filter(
                          (row: any) => row.id === params.id
                        );
                        let index = rows.indexOf(row[0]);
                        let data = [...rows];
                        let row_copy = { ...row[0] };
                        row_copy.stav = params.value;
                        row_copy.casPovodnejZmeny =
                          response.data[0].casPovodnejZmeny;
                        row_copy.povodnyStav = response.data[0].povodnyStav;
                        data[index] = row_copy;
                        setRows(data);

                        success(row[0].id);
                      })
                      .catch(function (error) {
                        errorHandleDialog(error.response, navigate, dispatch);
                      });
                  }
                }}
                initialState={{ pinnedColumns: { left: ["znacka"] } }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2} />
    </div>
  );
}

export default PrahaTable;
