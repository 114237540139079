﻿export function getRowStatus_isCorrect(value: string, columns: any) {
    let i = 0;
    let value_to_search;
    while (i < columns.length) {
        if (columns[i] !== null) { value_to_search = columns[i].toString().slice(0, value.length).toLowerCase() }
        if (value_to_search === value) {
            return true
        }
        i++;
    }
    return false
}
