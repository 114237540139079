import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../shared/Navbar";
import useWindowSize from "../../hooks/useWindow";
import * as urlHelp from "../../features/urlHelpers";
import {
  DataGridPremium,
  csCZ,
  GridColumns,
  useGridApiRef,
  GridRowsProp,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
//import { RootState } from "../../redux/store";
import Moment from "moment";
import { getLicense } from "../shared/license";
import { getRowStatus_isCorrect } from "../shared/search";
//import { logoutUser } from "../../redux/actions/authActions";
import { logout, log_or_not } from "../shared/authFunctions";
import axios from "axios";
import { loginData } from "../auth/redux/loginSlice";
import { errorHandleDialog } from "../shared/errorDialog";
import remove_accents from "remove-accents";
import styles from "./ZvyTable.module.sass";

function ZvyTable() {
  //const [ztpData, setZtpData] = useState([] as GridRowsProp); //data do tabulky ziskane z backendu
  const [rows, setRows] = useState([] as GridRowsProp);
  const [rows_loaded, setRowsLoaded] = useState(false as boolean);
  const [usek, setUsek] = useState("Vše");
  const [usek_options, setUsek_options] = useState(["Vše"] as any);
  const [kod, setKod] = useState("Vše");
  const [kod_options, setKod_options] = useState(["Vše"] as any);
  const [kod_nazov, setKod_nazov] = useState("");
  const [stav, setStav] = useState("Nie");
  const [stav_options, setStav_options] = useState(["Nie"] as any);
  const [searchValue, setSearchedValue] = useState("");
  const { width } = useWindowSize();
  const apiRef = useGridApiRef();
  const navigate = useHistory();
  //const logindata = useSelector((state: RootState) => state.login);
  //const zdata = useSelector((state: RootState) => state.ztp);
  const logindata = useSelector(loginData);
  const [date, setDate] = useState("");
  const dispatch = useDispatch();
  LicenseInfo.setLicenseKey(getLicense());

  async function getZtpData() {
    await axios
      .get(
        urlHelp.zvyUrl + "?datum_vyroba=" + urlHelp.get_datum_vyroba(logindata),
        urlHelp.get_header(logindata)
      )
      .then(function (response) {
        let i = 0;
        console.log("zvydata", response.data);
        const newData = response.data.data.map((obj: any) => ({
          ...obj,
          id: i++,
        }));

        //nastavenie moznosti pre usek
        let Usek_options_help = [...response.data.filter_usek];
        Usek_options_help.unshift("Vše");
        setUsek_options(Usek_options_help);

        //nastavenie moznosti pre stav

        setStav_options(response.data.filter_stav);

        //nastavenie moznosti pre znacku v cz
        let Kod_options_help = [...response.data.filter_kod_znacka_cz];
        Kod_options_help.unshift("Vše");
        setKod_options(Kod_options_help);

        setRows(newData);
        setDate(urlHelp.get_datum_vyroba(logindata).slice(0, 10));
        setRowsLoaded(true);
      })
      .catch(function (error) {
        errorHandleDialog(error.response, navigate, dispatch);
      });
  }

  useEffect(() => {
    log_or_not(logindata, navigate, dispatch);
    setRowsLoaded(false);
    getZtpData();
  }, []);

  const styleTable = {
    height: window.innerHeight - 210.8,
    width: "100%",
    margin: 0,
    "& .MuiDataGrid-cell--editable": {
      backgroundColor: "rgb(240,255,240)",
      "&:hover": {
        backgroundColor: "rgb(240,255,0)",
      },
    },
    "& .super-app-theme--1": {
      backgroundColor: "rgba(0,102,0,255)",
      color: "rgb(255, 255, 255)",
      "&:hover": {
        color: "rgb(0,0,0)",
      },
    },
    "& .super-app-theme--2": {
      backgroundColor: "rgb(73, 69, 41)",
      color: "rgb(255, 255, 255)",
      "&:hover": {
        color: "rgb(0,0,0)",
      },
    },
  };

  function CustomToolbar() {
    return (
      <div>
        <GridToolbarContainer>
          <div className={styles.GridToolbar}>
            <div>
              <GridToolbarExport
                printOptions={{
                  hideFooter: true,
                }}
                csvOptions={{
                  delimiter: ";",
                  utf8WithBom: true,
                  fileName: "ZVY",
                }}
              />
            </div>
            <div>Úsek: {usek}</div>
            <div>Výroba: {Moment(date).format("DD.MM.YYYY")} ZVY</div>
          </div>
        </GridToolbarContainer>
      </div>
    );
  }
  function CustomToolbarEmpty() {
    return <div></div>;
  }

  function filter(data: any) {
    let newData = data
      .filter(
        (row: any) =>
          String(
            remove_accents(row.znacka_sur ? row.znacka_sur.toUpperCase() : "")
          ).includes(remove_accents(searchValue.toUpperCase())) ||
          String(
            remove_accents(row.nazov_sur ? row.nazov_sur.toUpperCase() : "")
          ).includes(remove_accents(searchValue.toUpperCase())) ||
          row.typr === 2
      )
      .filter((row: any) => {
        if (
          ((usek === "Vše" || usek === row.usek) &&
            (kod === "Vše" || kod === row.znacka_cz) &&
            stav === row.stav) ||
          row.typr === 2
        ) {
          return true;
        } else {
          return false;
        }
      });

    //riesenie zobrazenia riadka typu 2 po filtraci
    return newData.filter((row: any) => {
      if (row.typr === 2 && newData.length <= 1) {
        return false;
      } else {
        return true;
      }
    });
  }

  const columns: GridColumns = [
    {
      field: "znacka_sur",
      headerName: "Kod suroviny",
      editable: false,
      width: 150,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "nazov_sur",
      headerName: " Název suroviny",
      editable: false,
      width: 260,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
    },

    {
      field: "mj",
      headerName: "MJ",
      width: 80,
      editable: false,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "nazov_vyr",
      headerName: "Název výrobku",
      width: 260,
      editable: false,
      pinnable: false,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "avg_navyseni",
      headerName: "Ø Navýšení",
      width: 150,
      type: "number",
      editable: false,
      pinnable: false,
      sortable: false,
      renderCell: (params: any) => {
        let valueFormatted = Number(params.value as number);

        let sum = 0;
        let medzi_sum = 0;

        if (params.row.typr === 2) {
          filter(rows).map((row: any) => {
            if (row.typr !== 2) {
              sum += row.avg_navyseni;
            }
          });
          valueFormatted =
            sum / filter(rows).filter((row: any) => row.typr === 0).length;
        }

        const value = valueFormatted
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$& ");

        return <div className={styles.cell}>{`${value} %`}</div>;
      },

      headerAlign: "center",
    },
    {
      field: "sum_surovina",
      headerName: "∑ Surovina",
      width: 150,
      type: "number",
      editable: false,
      pinnable: false,
      sortable: false,
      renderCell: (params: any) => {
        let valueFormatted = Number(params.value as number);

        let sum = 0;

        if (params.row.typr === 2) {
          filter(rows).map((row: any) => {
            if (row.typr !== 2) {
              sum += row.sum_surovina;
            }
          });
          valueFormatted = sum;
        }

        const value = valueFormatted
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$& ");

        return <div className={styles.cell}>{`${value}`}</div>;
      },
      headerAlign: "center",
    },
    {
      field: "sum_pripravna",
      headerName: "∑ Přípravna",
      width: 150,
      type: "number",
      editable: false,
      pinnable: false,
      sortable: false,
      renderCell: (params: any) => {
        let valueFormatted = Number(params.value as number);

        let sum = 0;

        if (params.row.typr === 2) {
          filter(rows).map((row: any) => {
            if (row.typr !== 2) {
              sum += row.sum_pripravna;
            }
          });
          valueFormatted = sum;
        }

        const value = valueFormatted
          .toFixed(4)
          .replace(/\d(?=(\d{3})+\.)/g, "$& ");

        return <div className={styles.cell}>{`${value}`}</div>;
      },
      headerAlign: "center",
    },
  ];

  return (
    <div>
      <Navbar />
      <div
        style={{
          height: window.innerHeight - 70.8,
        }}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <div>
              <div className={styles.selectArea}>
                <div>Úsek: </div>
                <div>
                  <select
                    className={styles.select}
                    value={usek}
                    onChange={(e) => setUsek(e.target.value)}
                    disabled={!rows_loaded}
                  >
                    {usek_options.map((option: any) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className={styles.selectArea}>
                <div>Stav: </div>
                <div>
                  <select
                    className={styles.select}
                    value={usek}
                    onChange={(e) => setStav(e.target.value)}
                    disabled={!rows_loaded}
                  >
                    {stav_options.map((option: any) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.selectArea}>
                <div>Kód výrobku v CZ: </div>
                <div>
                  <select
                    className={styles.select}
                    value={usek}
                    onChange={(e) => setKod(e.target.value)}
                    disabled={!rows_loaded}
                  >
                    {kod_options.map((option: any) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <input
              onChange={(e) => {
                setSearchedValue(e.target.value);
              }}
              className={styles.input}
              placeholder="Hledej"
            ></input>
            <div className={styles.title}>ZVY</div>
          </div>
          <div className={styles.grid}>
            {rows_loaded ? (
              <DataGridPremium
                sx={styleTable}
                disableColumnMenu={true}
                components={{
                  Toolbar:
                    rows.length === 1 ? CustomToolbarEmpty : CustomToolbar,
                }}
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                apiRef={apiRef}
                hideFooter
                rows={filter(rows)}
                columns={columns}
                rowHeight={25}
                getRowClassName={(params: any) =>
                  `super-app-theme--${params.row.typr}`
                }
                disableSelectionOnClick
                disableColumnReorder
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default ZvyTable;
